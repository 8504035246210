import React, { useState, useEffect, useRef } from 'react';
import OnboardingSlideshow from './OnboardingComponent/OnboardingSlideshow';
import colorJawebLogo from '../../../assets/Onboarding/colorLogo.png';
import { DownOutlined } from '@ant-design/icons';
import './SignupV2.css';
import axios from 'axios';
import { message } from 'antd';
import config from '../../../config';
import { useNavigate, useLocation } from 'react-router-dom';
import {LanguagesOptions, IndustriesOptions } from './LanguageOptions';
import shopifyLogo from '../../../assets/Onboarding/shopifyLogo.svg';
import leftArrow from '../../../assets/arrows/LeftArrowIcon.svg';

import { Alert, Calendar ,Badge} from 'antd'; // Import Alert and Calendar
import moment from 'moment'; // Import moment.js for date manipulation


export default function SignupShopify({}) {
  const navigate = useNavigate();
  const location = useLocation(); // To get the token from URL

  const {MakeStepToZero} =  location.state || {};

  
  const [currentStep, setCurrentStep] = useState(0);
  const [shop,setShop] = useState("")


 

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const shop = params.get('shop');
    const code = params.get('code');

    setShop(shop)

    if (!shop && !code){
      navigate('/auth/login')
    }

  }, [location,navigate]);


  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [isOtpError, setIsOtpError] = useState(false);
  // const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState('');
  const [progress,setProgress]=useState(false);
  const [isLoading,setIsLoading]=useState(false);
  const [isValid,setIsvalidation]=useState(false);
  const [trialDays,setTrialDays]=useState()
  const [handledSignup,setHandleSignup]=useState(false);
  const [simulationStep, setSimulationStep] = useState(0); // 0: Validating, 1: Creating Account, 2: Success


  const [timer, setTimer] = useState(60);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const [formData, setFormData] = useState({
    companyName: '',
    website: '',
    revenue: '',
    language: '',
    industryType: '',
    email: '',
    username: shop,
 
  });

  const [formErrors, setFormErrors] = useState({
    companyName: '',
    website: '',
    revenue: '',
    language: '',
    industryType: '',
    email: '',
    username: '',

  });



  const [isTokenValid, setIsTokenValid] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);


  const inputRef = useRef();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/user/chatbot');
    }
  }, [])
  



  // Parse the query parameters
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    
    if (currentStep === 3) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
      
      return () => clearInterval(interval);
    }
    
  }, [currentStep]);

  const validateField = (name, value) => {
    let error = '';
    if (!value.trim()) {
      error = `${name.charAt(0).toUpperCase() + name.slice(1).replace(/([A-Z])/g, ' $1')} is required`;
    }

    switch (name) {
      case 'email':
        if (value && !/\S+@\S+\.\S+/.test(value)) {
          error = 'Please enter a valid email address';
        }
        break;
      case 'website':
        if (value && !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value)) {
          error = 'Please enter a valid website URL';
        }
        break;
     
    }

    return error;
  };

  const validateStep = (step) => {
    let errors = {};
    let isValid = true;

    switch (step) {
      case 0:
        ['companyName', 'website'].forEach(field => {
          const error = validateField(field, formData[field]);
          if (error) {
            errors[field] = error;
            isValid = false;
          }
        });
        break;
      case 1:
        ['language', 'industryType'].forEach(field => {
          const error = validateField(field, formData[field]);
          if (error) {
            errors[field] = error;
            isValid = false;
          }
        });
        break;
      case 2:
        const emailError = validateField('email', formData.email);
        if (emailError) {
          errors.email = emailError;
          isValid = false;
        }
        break;
      case 4:
        const usernameError = validateField('username', shop);
        if (usernameError) {
          errors.username = usernameError;
          isValid = false;
        }
        break;
     
    }

    setFormErrors({ ...formErrors, ...errors });
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: ''
    });
  };

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setIsOtpError(false);
      if (value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const generateRandomNumberString = (length) => {
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      result += randomDigit;
    }
    return result;
  };

  const handleSendOtp = async () => {
    
    const otp = generateRandomNumberString(6);

    
    setGeneratedOtp(otp);    

    try {

      let tempEmail = formData.email
      
      const response = await axios.post(`${config.apiUrl}user/send-otp`, { email:tempEmail, otp, type: 'signup' });

      if (response.status === 200) {
        setDisableBtn(false)
        message.success('OTP sent successfully');
        setCurrentStep(currentStep + 1)

      } else {
        message.error('Failed to send OTP');
      }
      
    } catch (error) {
      if (error.name === 'Error' && error.errorFields) {
        message.error('Please fill out all required fields correctly.');
      } else {
        message.error('Error sending OTP');
      }
    }
  };


  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  const handleOtpVerification = (e) => {
    
    if (otp.join('') != generatedOtp) {
      setIsOtpError(true);
      return;
    }

    handleSignup()
    
    setIsOtpError(false);
    handleNextStep(e);
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setTimer(60);

    const otp = generateRandomNumberString(6);
    setGeneratedOtp(otp);

    try {

      let tempEmail = formData.email
      
      const response = await axios.post(`${config.apiUrl}user/send-otp`, { email:tempEmail, otp, type: 'signup' });

      if (response.status === 200) {
        message.success('OTP sent successfully');
      } else {
        message.error('Failed to send OTP');
      }
    } catch (error) {
      if (error.name === 'Error' && error.errorFields) {
        message.error('Please fill out all required fields correctly.');
      } else {
        message.error('Error sending OTP');
      }
    }

  }

  const handleEmailUniqueness = (e) => {
    e.preventDefault();
    setDisableBtn(true)

      let tempEmail = formData.email
      
      const reqFormData = new FormData();
      reqFormData.append('email', tempEmail);
    
      axios.post(`${config.apiUrl}unique-email/`, reqFormData).then((res) => { 
        // setDisableBtn(false)
        handleSendOtp()

      }).catch(error => {
        setDisableBtn(false)

        message.error('Account with this email already exists.');
        
      });

  }

  const handleNextStep = (e) => {
    e.preventDefault();
    if (validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSignup = (e) => {
    if (validateStep(4)) {
      console.log('Form submitted successfully:', formData);
      handleValidation()
    }
    else{
      console.log("Invalid steps ")
    }
  };







  const handleValidation = async () => {
    const verificationCodeUser = generateRandomNumberString(4);
    const access_token = params.get('accessToken');

    if (shop) {
      let reqFormData = new FormData();
      reqFormData.append('username', formData.username);
      reqFormData.append('company_type', formData.industryType);
      reqFormData.append('company_revenue', formData.revenue);
      reqFormData.append('language', formData.language);
      reqFormData.append('company_website', formData.website);
      reqFormData.append('username', shop); // Ensure 'shop' is defined
      const referralLink = localStorage.getItem('referral_link');
      
      if (referralLink) {
        reqFormData.append('referral', referralLink);
      }
      reqFormData.append('company_name', formData.companyName);
      reqFormData.append('email', formData.email);
      reqFormData.append('otp', verificationCodeUser);
      reqFormData.append('access_token',access_token)
      setSimulationStep(0); // Start the validation step in simulation
  
      try {
        const response = await axios.post(`${config.apiUrl}user/validate`, reqFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        if (response.status === 200) {
          // Store validated data in localStorage
          localStorage.setItem('username', shop);
          localStorage.setItem('email', formData.email);
          localStorage.setItem('otp', verificationCodeUser);
  
          // Optional: Stringify reqFormData contents
          const formDataObject = Object.fromEntries(reqFormData.entries());
          localStorage.setItem('signupFormData', JSON.stringify(formDataObject));
  
          // Proceed to account creation simulation
          handleSignupOnSuccessPage(); // Ensure this is awaited
        }
      } catch (error) {
        // Handle server validation error
        if (error.response) {
          console.error('Validation error:', error.response.data);
          message.error('Invalid Values. Please try again.', 4);
        } else if (error.request) {
          console.error('No response from server:', error.request);
          message.error('No response received from the server.', 4);
        } else {
          console.error('Error during validation:', error.message);
          message.error('Unexpected error occurred.', 4);
        }
      }
    } else {
      // Show error message if validations fail
      message.error('Shop details does not exist', 4);
    }
  };
  

  const handleSignupOnSuccessPage = async () => {
    setIsLoading(true);
    setSimulationStep(1); // Start with "creating account"

    const savedFormData = localStorage.getItem('signupFormData');
    if (!savedFormData) {
      throw new Error('No form data found');
    }

    // Parse the saved form data
    const parsedData = JSON.parse(savedFormData);


    try {
     
      const response = await axios.post(`${config.apiUrl}user/signup`, parsedData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { token } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('Userdata', JSON.stringify(response.data));
      
      setTrialDays(response.data.trial_days)
      console.log(trialDays)

      console.log(response.data.trial_days)


      setSimulationStep(2); 
    } catch (error) {
      message.error('Account creation failed');
    } finally {
      setIsLoading(false);
    }
  };

  const TypingEffectMessage = ({ message, keyIndex }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [charIndex, setCharIndex] = useState(0);
  
    useEffect(() => {
      if (charIndex < message.length) {
        const timeout = setTimeout(() => {
          setDisplayedText((prev) => prev + message[charIndex]);
          setCharIndex(charIndex + 1);
        }, 50);
        return () => clearTimeout(timeout);
      }
    }, [charIndex, message]);
  
    useEffect(() => {
      setDisplayedText('');
      setCharIndex(0);
    }, [keyIndex]);
  
    return <span className='font-bold'>{displayedText}</span>;
  };
  
  const AnimatedDots = () => {
    const [dots, setDots] = useState('');
  
    useEffect(() => {
      const interval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
      return () => clearInterval(interval);
    }, []);
  
    return <span>{dots}</span>;
  };
  

  return (
    <div className="signup-page-container">
      {windowWidth >= 1000 && <OnboardingSlideshow />}
      <div style={{ flex: '1' }}>

  

        {currentStep === 0 && (
          <div className="signup-container">
            {currentStep!==0?
                        <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>
              :<></>
          }
            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index <= currentStep ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">What's your company name?</h1>
            <p className="signup-subtitle">
              This will enable us to tailor our services to meet the specific needs of your business
            </p>
            <form onSubmit={handleNextStep}>
              <div className="signup-form-group">
                <label className="signup-label">Company Name*</label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  placeholder="Enter your Company Name"
                  className={`signup-input ${formErrors.companyName ? 'input-error' : ''}`}
                />
                {formErrors.companyName && (
                  <p className="error-message">{formErrors.companyName}</p>
                )}
              </div>
              <div className="signup-form-group">
                <label className="signup-label">Website*</label>
                <input
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleInputChange}
                  placeholder="Enter your website link"
                  className={`signup-input ${formErrors.website ? 'input-error' : ''}`}
                />
                {formErrors.website && (
                  <p className="error-message">{formErrors.website}</p>
                )}
              </div>
              <div className="signup-form-group">
                <label className="signup-label">Revenue* (optional)</label>
                <input
                  type="text"
                  name="revenue"
                  value={formData.revenue}
                  onChange={handleInputChange}
                  placeholder="Enter your company revenue"
                  className={`signup-input`}
                />

              </div>
              <button type="submit" className="signup-button">
                Next
              </button>
            </form>
          </div>
        )}

        {currentStep === 1 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>

            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index <= currentStep ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">What language are your customers comfortable with?</h1>
            <p className="signup-subtitle">
              Understanding your language preference will allow us to create a better experience for you
            </p>
            <form onSubmit={handleNextStep}>
              <div className="signup-form-group">
                <label className="signup-label">Language*</label>
                <div className="signup-select-wrapper">
                  <select
                    name="language"
                    value={formData.language}
                    onChange={handleInputChange}
                    className={`signup-select ${formErrors.language ? 'input-error' : ''}`}
                  >
                    <option value="">Select language</option>

                    {
                      LanguagesOptions.map((lang, index) => (
                        <option value={lang.name}>{lang.name}</option>
                      ))
                    }

                  </select>
                  <div className="signup-select-icon">
                    <DownOutlined size={20} color="#666" />
                  </div>
                </div>
                {formErrors.language && (
                  <p className="error-message">{formErrors.language}</p>
                )}
              </div>
              <div className="signup-form-group">
                <label className="signup-label">Industry Type*</label>
                <div className="signup-select-wrapper">
                  <select
                    name="industryType"
                    value={formData.industryType}
                    onChange={handleInputChange}
                    className={`signup-select ${formErrors.industryType ? 'input-error' : ''}`}
                  >
                    <option value="">Select industry type</option>
                    {
                      IndustriesOptions.map((index) => (
                        <option value={index}>{index}</option>
                      ))
                    }

                  </select>
                  <div className="signup-select-icon">
                    <DownOutlined size={20} color="#666" />
                  </div>
                </div>
                {formErrors.industryType && (
                  <p className="error-message">{formErrors.industryType}</p>
                )}
              </div>
              <button type="submit" className="signup-button">
                Next
              </button>
            </form>
          </div>
        )}

        {currentStep === 2 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>

            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index <= currentStep ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">What's your email address?</h1>
            <p className="signup-subtitle">
              This will be your registered address for all the essential communications
            </p>
            <form onSubmit={handleEmailUniqueness}>
              <div className="signup-form-group">
                <label className="signup-label">Email Address*</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email address"
                  className={`signup-input ${formErrors.email ? 'input-error' : ''}`}
                />
                {formErrors.email && (
                  <p className="error-message">{formErrors.email}</p>
                )}
              </div>
              <button type="submit" className="signup-button" disabled={ disableBtn ? true : false}>
                {disableBtn ? 'Sending Otp' : 'Next'}
              </button>
            </form>
          </div>
        )}

        {currentStep === 3 && (
          <div className="signup-container">
            <img src={leftArrow} style={{height:'50px', width:'30px', marginBottom:'50px', cursor:'pointer'}} onClick={()=>setCurrentStep(currentStep-1)}/>

            <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo" />
            <div className="signup-progress-bar">
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  className={`signup-progress-step ${index < 4 ? 'signup-progress-step-active' : ''}`}
                />
              ))}
            </div>
            <h1 className="signup-title">Got our email?</h1>
            <p className="signup-subtitle">
              We've sent a One-Time-Password (OTP) to your email address
            </p>
            <form onSubmit={handleOtpVerification}>
              <p className="signup-timer-text">OTP</p>
              <div className="signup-otp-container">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    maxLength="1"
                    className={`signup-otp-input ${isOtpError ? 'otp-input-signup-error' : ''}`}
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    required
                  />
                ))}
              </div>
              
              {isOtpError && (
                <p className="signup-error-text">The OTP you've entered is invalid</p>
              )}
              <p className="signup-timer-text">{timer} sec</p>
              <p 
                className="signup-resend-link" 
                onClick={(e)=>(handleResendOtp(e))}
              >
                Didn't receive the code? Re-send OTP
              </p>
              <button 
                type="submit" 
                className="signup-button"
                disabled={otp.some(digit => !digit)}
              >
                Next
              </button>
            </form>
          </div>
        )}

{currentStep === 4 && (
  <div
    className={`signup-container flex flex-col items-center justify-center min-h-screen transition-colors duration-1000 ${
      simulationStep === 0
        ? 'bg-white' // Light purple for validation
        : simulationStep === 1
        ? 'bg-white' // Medium purple for creation
        : 'bg-white' // Theme purple for success
    }`}
  >
    <img src={colorJawebLogo} alt="Jaweb logo" className="signup-logo mb-6" />

    {/* Progress Bar */}
    <div className="signup-progress-bar flex gap-2 mb-6">
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className={`signup-progress-step ${
            index <= currentStep ? 'signup-progress-step-active' : ''
          }`}
        />
      ))}
    </div>

    {/* Simulated Process */}
    <div className="flex flex-col items-center text-center">
      {simulationStep === 0 && (
        <p className="text-lg text-gray-800 mb-4">
          <TypingEffectMessage
            message="Your details are being validated"
            keyIndex={simulationStep}
          />
          <AnimatedDots />
        </p>
      )}
      {simulationStep === 1 && (
        <p className="text-lg text-gray-800 mb-4">
          <TypingEffectMessage
            message="Your account is being created"
            keyIndex={simulationStep}
          />
          <AnimatedDots />
        </p>
      )}
      {simulationStep === 2 && (
        <div>
          <h2 className="text-2xl font-bold mb-4 text-black">🎉 Account Created Successfully!</h2>
          <p className="mb-6 text-lg text-black">
            Your Jaweb account is ready to go. We're excited to have you on board!
          </p>
          <button
            onClick={() => navigate('/user/welcome/tour')}
            className="mb-4 px-3 py-2 bg-[#7F28F8] hover:bg-gray-200 text-[white] font-semibold rounded transition duration-300"
          >
            Go to Dashboard
          </button>
         
        </div>
      )}
    </div>
  </div>
)}



        
      </div>
    </div>
  );
}