import React, { useState } from "react";
import { Button, Input, Upload, Steps, Typography,Avatar,message } from "antd";
import { motion } from "framer-motion";
import { PlusOutlined, ArrowRightOutlined,UserOutlined } from "@ant-design/icons";
import Lottie from "lottie-react";
import './assets/css/ChatbotSetting.css';
import config from "../../../config";
import CustomLayout from "../../../layouts/Customlayout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import setupAnimation from "./assets/Animation-setup.json";
import typingAnimation from "./assets/Animation-typing.json";
import customizationAnimation from "./assets/Animation -customization.json";
import featuresAnimation from "./assets/Animation -features.json";
import successAnimation from "./assets/Animation -success.json";

const { Title, Text } = Typography;

const steps = [
    { title: "Basic Info", description: "Set chatbot name & image" },
    { title: "Messages", description: "Configure welcome & quick replies" },
    { title: "Appearance", description: "Choose colors & theme" },
    { title: "Features", description: "Enable advanced features" },
    { title: "Preview", description: "See how it looks" },
  ];


  
  const ChatbotSetup = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [chatbotName, setChatbotName] = useState("Jaweb Chatbot");
    const [imageFile, setImageFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [initialMessage, setInitialMessage] = useState("Hello! How can I help you?");
    const [suggestedMessages, setSuggestedMessages] = useState(["What do you sell?", "What is your contact?"]);
    const [sections, setSections] = useState([
        { id: 1, value: '' },
    
      ]);

      const navigate = useNavigate();
    
    const [Loader,setLoader]=useState(false)
    const [selectedColor, setSelectedColor] = useState("#7F28F8");
    const [humanTakeover, setHumanTakeover] = useState(false);
    const [toggleEmailSummary, setToggleEmailSummary] = useState(false);
    const [formData, setFormData] = useState({
        first_name: "",
        image: null,
        password:'',
        confirmPassword:'',
      });
    // Handle Image Upload
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          setImageFile(file);
          setPreviewImage(URL.createObjectURL(file));
        }
      };
  
    const nextStep = () => {
      if (currentStep < steps.length - 1) {
        setCurrentStep((prev) => prev + 1);
      }
    };
  
    const prevStep = () => {
      if (currentStep > 0) {
        setCurrentStep((prev) => prev - 1);
      }
    };

    const handleSaveBtn = () => {

        setLoader(true)
    
        const userSuggestions = sections.map(i => i.value)
        console.log(initialMessage, imageFile, selectedColor, userSuggestions, toggleEmailSummary)
    
        const formData = new FormData();
        formData.append('chatbot_color', selectedColor);
        formData.append('chatbot_initial_msg', initialMessage);
        formData.append('disable_chatbot_globally', humanTakeover);
        formData.append('enable_chat_summary', toggleEmailSummary)
        if (imageFile) {
          formData.append('chatbot_logo', imageFile);
        }

        formData.append('firstLogin',false)
    
        if (userSuggestions[0] != '') {
          formData.append('user_questions', userSuggestions)
        }
    
        const headers = {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Accept': 'application/json',
        };
    
        axios.post(`${config.apiUrl}chatbot-details/`, formData, { headers })
          .then(res => {
            message.success('Settings saved successfully');
    
            setLoader(false)
            window.location.reload();
            navigate("/user/integration");
    
          })
          .catch(error => {
            setLoader(false)
            message.error('Error saving settings');
            console.error('Error posting data:', error);
          });
      };
    
  
    return (

      <div className="setup-container">
        {/* Step Indicator */}
        <Steps current={currentStep} className="steps-bar">
          {steps.map((step, index) => (
            <Steps.Step key={index} title={step.title} />
          ))}
        </Steps>
  
        <motion.div
          key={currentStep}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-content"
        >
          {/* Step 1: Chatbot Basic Info */}
          {currentStep === 0 && (
            <div>
              <Lottie animationData={setupAnimation} style={{ width: 200, margin: "auto" }} />
              <Title level={3}>What should I be called?</Title>
              <div className="mb-4">
              <span className="text-gray-500 text-[19px]">Enter a name that your chatbot will be addressed as</span>

              </div>
              <Input
                placeholder="Enter chatbot name"
                className="p-2"
                value={chatbotName}
                onChange={(e) => setChatbotName(e.target.value)}
              />
              <div className="mt-6">
              <Title level={3}>Can I get a picture?</Title>
              <div className="mb-4">
              <span className="text-gray-500 text-[19px]">Select a profile picture for your chatbot </span>

              </div>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="upload-input"
                onChange={(e) => handleImageChange(e)}
              
                />
            <label htmlFor="upload-input">
            <Avatar
                size={128}
                src={previewImage || undefined} // Use the preview URL
                style={{
                border: "3px solid #7F28F8",
                backgroundColor: "#f0f0f0",
                cursor: "pointer",
                marginBottom: 10,
                }}
            >
                {!previewImage && <UserOutlined style={{color:'black'}}/>} {/* Fallback for no image */}
            </Avatar>
            </label>              
            
            </div>
        
            </div>
          )}
  
          {/* Step 2: Messages */}
          {currentStep === 1 && (
            <div>
              <Lottie animationData={typingAnimation} style={{ width: 200, margin: "auto" }} />
              <Title level={3}>Chatbot Messages</Title>
              <div className="mb-4">

              <span className="text-gray-500 text-[19px]">What should your chatbot say when greeting users?</span>
            </div>
              <Input
                placeholder="Hello! How can I help you?"
                className="p-2 mb-4"
                value={initialMessage}
                onChange={(e) => setInitialMessage(e.target.value)}
              />

              <Title level={3}>Quick Reply Suggestions</Title>

              <div className="mb-4">
                <span className="text-gray-500 text-[19px]">Provide your customers with some questions</span>
            </div>
            {sections.map((section, index) => (
                <Input
                  key={section.id}
                  className="mt-2"
                  placeholder="Enter a message"
                  value={section.value}
                  onChange={(e) => {
                    const updated = [...sections];
                    updated[index].value = e.target.value;
                    setSections(updated);
                  }}
                />
              ))}
              <Button className="mt-4" onClick={() => setSections([...sections, { id: sections.length + 1, value: "" }])}>➕ Add</Button>
           
            </div>
          )}
  
          {/* Step 3: Chatbot Appearance */}
          {currentStep === 2 && (
            <div>
              <Lottie animationData={customizationAnimation} style={{ width: 200, margin: "auto" }} />
              <Title level={3}>Customize Chatbot Appearance</Title>
              <div className="mb-4">
                <span className="text-gray-500 text-[19px]">Pick a primary color for your chatbot</span>
               </div>

              <input

                type="color"
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
                className="color-picker w-[50%]"
              />
            </div>
          )}
  
          {/* Step 4: Features */}
          {currentStep === 3 && (
            <div>
              <Lottie animationData={featuresAnimation} style={{ width: 200, margin: "auto" }} />
              <Title level={3}>Advanced Features</Title>

              <div className="mb-4">
                <span className="text-gray-500 text-[19px]">Pick any of these advanced features</span>
               </div>
                <div className="flex gap-6 justify-center items-center">
          
                <input
                  type="checkbox"
                  className=" w-6 h-6"
                  checked={humanTakeover}
                  onChange={() => setHumanTakeover(!humanTakeover)}
                />{" "}
                Enable Human Takeover
            

   
                <input
                  type="checkbox"
                  className=" w-6 h-6"
                  checked={toggleEmailSummary}
                  onChange={() => setToggleEmailSummary(!toggleEmailSummary)}
                />{" "}
                Send Chat Summary via Email
          
                </div>


            </div>
          )}
  
          {/* Step 5: Chatbot Preview */}
          {currentStep === 4 && (
            <div className="flex flex-col justify-center items-center">
              <Lottie animationData={successAnimation} style={{ width: 200, margin: "auto" }} />
              <Title level={3}>🎉 Preview Your Chatbot</Title>
                  <div class="chat-box" style={{ border: `1px solid ${selectedColor}`,width:'50%' }}>
                    <div class="header_chat" style={{ background: selectedColor }}>
                      <div>
                        <img src={previewImage ? previewImage : "https://www.shutterstock.com/image-vector/chat-bot-logo-design-concept-600nw-1938811039.jpg" } alt="Logo" />
                      </div>

                    </div>

                    <div class="messages-container">
                      {initialMessage && (
                        <div className="message business-message">
                          <p className='initialMsg_text'>{initialMessage}</p>
                        </div>
                      )}
                  
                    </div>
                    {sections.some(section => section?.value.trim() !== '') && (
                      <div className="premadechat-wrapper-new">
                        {sections.map((data) => (
                          data?.value.trim() !== '' && (
                            <div className="conversation-item-text-new" key={data.id}>
                              <p>{data.value}</p>
                            </div>
                          )
                        ))}
                      </div>
                    )}

                    <div class="input-container">
                      <input type="text" style={{ border: `1px solid ${selectedColor}` }} />
                      <button type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="_svg_1cum9_1"><path d="M19.5656 12.5913C19.4092 12.9268 19.1426 13.1929 18.8233 13.3529L6.75036 19.4461C5.89387 19.8649 4.86032 19.5233 4.42563 18.6844C4.2343 18.2793 4.19549 17.822 4.32795 17.3955L5.64831 13.0972C5.7692 12.7037 6.13274 12.435 6.54448 12.435H11.1654C11.505 12.4337 11.7828 12.1592 11.7905 11.8101C11.7884 11.4663 11.514 11.1885 11.1654 11.1851H6.54788C6.13452 11.1851 5.76995 10.9144 5.65042 10.5187L4.34749 6.20514C4.07489 5.3055 4.58948 4.34594 5.50008 4.07643C5.92157 3.94393 6.37753 3.98415 6.76989 4.19362L18.8233 10.2868C19.6653 10.7168 20.0041 11.7517 19.5656 12.5913Z" fill={selectedColor}></path></svg>
                      </button>
                    </div>
                 

                    <div className="flex items-center justify-center">
                    <a  href="https://jaweb.me/" target="_blank" rel="noopener noreferrer">
                      <span className="text-gray-500">
                      Powered By Jaweb

                        </span>
                    </a>
                  </div>
              
                  </div>
                </div>
          
          )}
        </motion.div>
  
        {/* Navigation Buttons */}
        <div className="button-group">
          {currentStep > 0 && <Button onClick={prevStep}>Back</Button>}
          {currentStep < steps.length - 1 ? (
            <Button type="primary" className="bg-[#7F28F8]" onClick={nextStep} icon={<ArrowRightOutlined />}>
              Next
            </Button>
          ) : (
            <Button loading={Loader} className="bg-[#7F28F8]" type="primary" onClick={() => handleSaveBtn()}>Save & Finish </Button>
          )}
        </div>
      </div>

    );
  };
  
  

export default ChatbotSetup;
