import React, { useState, useEffect, useRef } from 'react';
import {
  IoSendSharp,
  IoChevronBack,
} from 'react-icons/io5';
import EmojiPicker from 'emoji-picker-react';
import axios from 'axios';
import config from '../../config';
import { message,Switch,Tooltip } from 'antd';
import WhatsappChatview from './WhatsappChatview';
import { UserOutlined,SmileOutlined,RobotOutlined,ClockCircleOutlined} from '@ant-design/icons';
import InstagramChatView from './InstagramChatView';

export default function InstagramChat({selectedChat, messages, setMessages, messageInput, setMessageInput, handleSendMessage, isMobile, setSelectedChat,connected }) {
    const emojiPickerRef = useRef(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [Opened,setOpen]=useState(false)
    const [teamMembers,setTeamMembers]=useState()
    const isInvited = localStorage.getItem("invited") === "true"; 
    const [aiEnabled, setAiEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state
  
    console.log(messages)
  
  
  
    const checkChatbotStatus = async (sessionId, sessionType) => {
      try {
        const response = await axios.get(`${config.apiUrl}disable-chatbot/`, {
          params: {
            session_id: sessionId,
            session_type: sessionType,
          },
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        });
  
        if (response.status === 200) {
          console.log('Chatbot Status:', response.data.disable_chatbot);
          return response.data.disable_chatbot;
        }
      } catch (error) {
        console.error('Error fetching chatbot status:', error);
      }
    };
  
    useEffect(() => {
      const fetchChatbotStatus = async () => {
        const isDisabled = await checkChatbotStatus(selectedChat.session_id, 'instagram');
        setAiEnabled(isDisabled); // Update toggle based on status
      };
  
      if (selectedChat) {
        fetchChatbotStatus();
      }
    }, [selectedChat]);
  
  
    const handleBotControl = async (checked) => {
      setIsLoading(true); // Start loading
  
      let formData = new FormData();
      formData.append('session_id', selectedChat.session_id);
      formData.append('session_type', 'instagram');
      formData.append('toggle_action', checked); // Use the new value of the toggle
  
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
          Accept: 'application/json',
        },
      };
  
      try {
        const response = await axios.post(
          `${config.apiUrl}disable-chatbot/`,
          formData,
          headers
        );
  
        console.log(response.data, '=--------bot control');
        setAiEnabled(checked); // Update the toggle state only on success
      } catch (error) {
        console.error('Error toggling bot control:', error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    };
  
     console.log(isInvited)
     console.log(JSON.parse(localStorage.getItem('UserObject')).id)
  
    const handleUpdateData = (newData) => {
      setSelectedChat(newData);
    };
  
  
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/administration/team/`, {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        });
        setTeamMembers(response.data[0]?.memberships || []);
      } catch (error) {
        message.error('Failed to load team members.');
      } finally {
      }
    };
  
    useEffect(()=>{
      fetchTeamMembers();
    },[])
  
    const closeUserProfile = () => {
      setOpen(false)
    };
  
  
    const handleEmojiClick = (emojiObject) => {
      setMessageInput((prev) => prev + emojiObject.emoji);
    };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
          setShowEmojiPicker(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    
  return (
      <main
      className={`flex-grow flex overflow-y-auto flex-col bg-white h-screen ${
        isMobile && !selectedChat ? 'hidden' : 'block'
      }`}
    >
      {selectedChat ? (
        <div className="flex flex-col h-[80vh]">
          <div className="flex items-center justify-between p-4 bg-gray-100 border-b">
            <button
              className="text-purple-600 font-medium sm:hidden"
              onClick={() => setSelectedChat(null)}
            >
              <IoChevronBack size={24} />
            </button>
            <h2 className="font-semibold">{selectedChat?.display_name}</h2>
        
           
            <Tooltip
            title={
              isInvited && selectedChat?.assigneeID !== JSON.parse(localStorage.getItem('UserObject'))?.id
                ? "You do not have control over this conversation"
                : aiEnabled
                ? "You are in control of the conversation"
                : "AI is responding to messages"
            }
            placement="bottom"
          >
            <div className="flex items-center gap-2">
              {/* Disable Switch for Invited Users Not Assigned */}
              <Switch
                loading={isLoading}
                disabled={
                  isInvited && selectedChat?.assigneeID !== JSON.parse(localStorage.getItem('UserObject'))?.id
                }
                onChange={handleBotControl} // Trigger API call on toggle
                checked={aiEnabled}
                checkedChildren={< UserOutlined/>} // Icon for "AI On"
                unCheckedChildren={<RobotOutlined />} // Icon for "AI Off"
              />
              <span className="text-sm font-medium text-gray-700">
                {aiEnabled ? "Human Control" : "AI Enabled"}
              </span>
            </div>
          </Tooltip>
  
  
            <button onClick={()=>{setOpen(!Opened)}} className="text-gray-600 hover:text-purple-600">
              <UserOutlined style={{fontSize:25}} />
            </button>
          </div>
          <div className="flex-grow p-4 overflow-y-auto bg-gray-50">
            <div className='flex justify-center'>
            {connected === "Connecting" && (
                <div style={{ color: "lightblue", fontSize: "15px" }}>Connecting ••</div>
              )}
              {connected === "Connected" && (
                <div style={{ color: "lightgreen", fontSize: "15px" }}>Connected •</div>
              )}
              {connected === "Disconnected" && (
                <div style={{ color: "lightcoral", fontSize: "15px" }}>Disconnected •</div>
              )}
            </div>
  
            {messages?.length > 0 ? (
              messages.map((message, id) => (
                  <div
                  key={id}
                  className={`flex flex-col ${
                      !message?.from_bot ? 'items-start' : 'items-end'
                  } mb-6`}
                  >
                  <div
                      className={`max-w-xs p-4 rounded-lg shadow-md ${
                      !message?.from_bot
                          ? 'bg-purple-600 text-white'
                          : 'bg-blue-100 text-gray-800'
                      }`}
                  >
                      {message.message}
                  </div>
                  <div className="flex items-center mt-2 space-x-1">
                     
                      {message?.from_bot?
                       <span className="text-xs text-gray-700 border border-gray-300 px-2 py-1 rounded-full bg-gray-100 shadow-sm">
                          {message.sender}
                      </span>
                      :<></>}
              
                  {message?.from_bot ? (
                  <>
                      {message.status === 'sending' && (
                      <ClockCircleOutlined style={{ fontSize: 12 }} />
                      )}
                      {message.status === 'sent' && (
                      <span className="text-blue-500" style={{ fontSize: 12 }}>✔</span>
                      )}
                      {message.status === 'delivered' && (
                      <span className="text-blue-500" style={{ fontSize: 12 }}>✔✔</span>
                      )}
                  </>
                  ) : null}
                  </div>
                  </div>
              ))
              ) : (
              <p className="text-center text-gray-500">No messages yet. Start the conversation!</p>
              )}
  
          </div>
  
          <div className="p-4 bg-gray-100 border-t flex items-center space-x-3">
            <button
              type="button"
              onClick={() => setShowEmojiPicker((prev) => !prev)}
              className="text-gray-500 hover:text-purple-600"
            >
              <SmileOutlined style={{fontSize:25}}/>
            </button>
            {showEmojiPicker && (
              <div ref={emojiPickerRef} className="absolute bottom-16">
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </div>
            )}
            <input
              type="text"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              disabled={isInvited && selectedChat.assigneeID !==  JSON.parse(localStorage.getItem('UserObject')).id}
              placeholder="Type a message..."
              className="flex-grow p-3 border rounded-full focus:outline-none "
            />
            <button
              type="button"
              onClick={handleSendMessage}
              disabled={ isInvited && selectedChat.assigneeID !==  JSON.parse(localStorage.getItem('UserObject')).id}
              className="bg-[#d62976] text-white p-3 rounded-full shadow-lg hover:bg-green-400"
            >
              <IoSendSharp size={24} />
            </button>
          </div>
        </div>
      ) : (
        <div className="flex-grow flex items-center justify-center">
          <p className="text-gray-500">Select a chat to see the content.</p>
        </div>
      )}
      <InstagramChatView visible={Opened} isInvited={isInvited}  onUpdateData={handleUpdateData}  close={() => { closeUserProfile() }} data={selectedChat} team={teamMembers}/>
  
    </main>
    )
  }