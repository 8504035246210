import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Card, Tag, Row, Col, Upload, message, Image, Collapse, Spin } from 'antd';
import { PlusOutlined, UploadOutlined, PlayCircleOutlined } from '@ant-design/icons';
import CustomLayout from '../layouts/Customlayout';
import config from '../config';

const { Panel } = Collapse;

export default function TicketSystem() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const headers = {
    Authorization: `Token ${localStorage.getItem('token')}`,
  };

  // Fetch Tickets
  const fetchTickets = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}user/tickets/`, {
        method: 'GET',
        headers,
      });
      if (!response.ok) throw new Error('Failed to fetch tickets');
      const data = await response.json();
      setTickets(data);
      console.log(data)
    } catch (error) {
      message.error('Error fetching tickets: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Component Did Mount
  useEffect(() => {
    fetchTickets();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const postTicket = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('status', 'Open');
    if (values.image && values.image.file) {
        console.log(values.image.fileList[0].originFileObj);
        formData.append('image', values.image.fileList[0].originFileObj); // Append the actual file object
      }
    try {
      const response = await fetch(`${config.apiUrl}user/tickets/`, {
        method: 'POST',
        headers,
        body: formData,
      });

      if (!response.ok) throw new Error('Failed to create ticket');
      const ticket = await response.json();
      setTickets((prevTickets) => [...prevTickets, ticket]);
      message.success('Ticket created successfully!');
    } catch (error) {
      message.error('Error creating ticket: ' + error.message);
    }
  };

  const handleFinish = (values) => {
    postTicket(values);
    setIsModalVisible(false);
  };

  const handleFileChange = (info) => {
    if (info.file.status !== 'uploading') {
      form.setFieldsValue({ image: info });
    }
  };

  const statusColors = {
    Open: '#FF6B6B', // Muted red
    'In Progress': '#6C5CE7', // Purple
    Solved: '#28A745', // Green
  };

  const tagStyle = (color) => ({
    color,
    border: `1px solid ${color}`,
    backgroundColor: 'transparent',
  });

  const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
  const isShopifyUser = UserInfo?.mode === 'shopify';
  
  // Define integration videos conditionally based on user mode
  const integrationVideos = [
    {
      title: 'Zapier Integration',
      description: '🔗 Automate workflows and integrate your chatbot with hundreds of apps using Zapier.',
      videoUrl: 'https://www.youtube.com/embed/k0Oaw38PnBg',
    },
    {
      title: 'WhatsApp Integration',
      description: '📱 Connect your chatbot to WhatsApp to engage with your customers on their favorite platform.',
      videoUrl: 'https://www.youtube.com/embed/cbOhTTX-12w',
    },
    ...(isShopifyUser
      ? [
          {
            title: 'Getting Started with Shopify Integration',
            description: '🛍️ Learn how to seamlessly integrate your chatbot with Shopify to assist customers during their shopping journey and boost sales.',
            videoUrl: 'https://www.youtube.com/embed/example9',
          },
        ]
      : [
          {
            title: 'Website Integration',
            description: '🖥️ Learn how to seamlessly integrate your chatbot into your website to engage visitors effectively and increase customer satisfaction.',
            videoUrl: 'https://www.youtube.com/embed/YroFFkZRDx8',
          },
          {
            title: 'WordPress Integration',
            description: '🖥️ Seamlessly integrate your chatbot with WordPress to improve customer engagement.',
            videoUrl: 'https://www.youtube.com/embed/86lP7tXuwD4',
          },
        ]),
  ];
  
  // Help Videos including conditional integration group
  const helpVideos = [
    {
      title: 'Getting Started with Customization',
      description: '🛠️ Learn how to customize your chatbot’s appearance and behavior to fit your business needs.',
      videoUrl: 'https://www.youtube.com/embed/mfiRVlhjh1s',
    },
    {
      title: 'Getting Started with Leads',
      description: '📈 Understand how to manage and nurture leads to convert visitors into customers effectively.',
      videoUrl: 'https://www.youtube.com/embed/m_66IvkTRFA',
    },
    {
      group: 'Integrations',
      items: integrationVideos, // Use the dynamically generated integrations array
    },
    {
      title: 'Getting Started with Bot Training Page',
      description: '🤖 Master the art of training your chatbot to understand customer queries better and provide accurate responses.',
      videoUrl: 'https://www.youtube.com/embed/8dXMk-GdJ88',
    },
    {
      title: 'Getting Started with Team Page',
      description: '👥 Manage your team effectively and assign roles for better collaboration.',
      videoUrl: 'https://www.youtube.com/embed/LXgd4h-sVyk',
    },
    {
      title: 'Getting Started with Account Settings Page',
      description: '⚙️ Configure your account settings to ensure everything works the way you want.',
      videoUrl: 'https://www.youtube.com/embed/example9',
    },
  ];
  
  

  return (
    <CustomLayout>
      <div className="ticket-system-container">
        <div className='help-section'>
        <div className="ticket-system-header">
          <h2 className="title font-bold">Ticket</h2>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className='bg-[#9E41FF]'
      
            onClick={showModal}
          >
            Create Ticket
          </Button>
        </div>

        <Row gutter={[16, 16]} className="ticket-list">
          {loading ? (
            <div className='flex justify-center items-center'>
                <div>
                <Spin size='small'/>
                </div>
            </div>
          ) : (
            tickets.map((ticket) => (
              <Col xs={24} sm={12} md={8} lg={6} key={ticket.id}>
                <Card
                  hoverable
                  style={{
                    borderTop: `5px solid ${statusColors[ticket.status]}`,
                    height: '100%',
                  }}
                  className="ticket-card"
                >
                  <div className="ticket-card-content">
                    <h3 className="ticket-title">{ticket.title}</h3>
                    <Tag style={tagStyle(statusColors[ticket.status])}>{ticket.status}</Tag>
                  </div>
                  <p className="ticket-description">{ticket.description}</p>
                  {ticket.image && (
                    <div className="ticket-image">
                      <Image
                        width="100%"
                        src={ticket.image}
                        placeholder={
                          <Image preview={false} src={ticket.image} width="100%" />
                        }
                      />
                    </div>
                  )}
                </Card>
              </Col>
            ))
          )}
        </Row>

        <Modal
          title="Create a New Ticket"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please enter a title!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please enter a description!' }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item name="image" label="Upload Image">
              <Upload
                listType="picture"
                maxCount={1}
                onChange={handleFileChange}
                beforeUpload={() => false}
              >
                <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        </div>


        {/* Help Section */}
        <div className="help-section mt-5">
            <h2 className="title font-bold">Help Section</h2>
            <Collapse accordion>
                {helpVideos.map((video, index) =>
                video.group ? (
                    <Panel
                    header={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PlayCircleOutlined style={{ fontSize: '20px', marginRight: '10px', color: '#6C5CE7' }} />
                        {video.group}
                        </div>
                    }
                    key={`group-${index}`}
                    >
                    {video.items.map((item, subIndex) => (
                        <div key={`item-${subIndex}`} className="integration-item">
                        <h3 style={{ fontWeight: 'bold', marginBottom: '5px' }}>{item.title}</h3>
                        <p>{item.description}</p>
                        <iframe
                            width="100%"
                            height="315"
                            src={item.videoUrl}
                            title={item.title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <hr style={{ margin: '20px 0' }} />
                        </div>
                    ))}
                    </Panel>
                ) : (
                    <Panel
                    header={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PlayCircleOutlined style={{ fontSize: '20px', marginRight: '10px', color: '#6C5CE7' }} />
                        {video.title}
                        </div>
                    }
                    key={index}
                    >
                    <p>{video.description}</p>
                    <iframe
                        width="100%"
                        height="315"
                        src={video.videoUrl}
                        title={video.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    </Panel>
                )
                )}
            </Collapse>
            </div>

      </div>
    </CustomLayout>
  );
}
