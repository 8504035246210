import React from 'react'
import { Image } from 'antd';
import config from '../../config';
import CustomLayout from '../../layouts/Customlayout';
import SettingOption from './Settingsoptions';
import './Css/wordpress.css'
import { useTranslation } from 'react-i18next';
import firstImage from './Images/WordPress1.png'
import secondImage from './Images/WordPress2.png'

import thirdImage from './Images/Wordpress3.png'
import fourthImage from './Images/WordPress4.png'
import fifthImage from './Images/WordPress5.png'


const handleDownload = async () => {
    try {
      const response = await fetch(`${config.apiUrl}user/download`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/zip', // Set the appropriate content type for your zip file
        },
      });

      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'JawebPlugin.zip';  // Set the desired name for the downloaded file

        // Trigger a click on the link to initiate the download
        link.click();

        // Clean up the URL object
        window.URL.revokeObjectURL(link.href);
      } else {
        console.error('Failed to download file:', response.statusText);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

export default function Wordpress() {
  const { t } = useTranslation();
  const integrationTranslations = t("integration")

  return (
    <CustomLayout>
      <div className='flex flex-col md:flex-row gap-6'>
        <div className='md:w-1/6'>
          <SettingOption />
        </div>
        <div className='flex-1'>
          <div style={{ padding: '20px' }}>
            <span style={{ fontSize: '17px', fontWeight: 'bold', paddingRight: '10px' }}>{ integrationTranslations.wordpressInstall}</span>
            <span>{ integrationTranslations.followSteps}</span>
          </div>
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>1.</span>
            <span>{ integrationTranslations.wstep1}</span>
            <div style={{ marginTop: '20px' }}>
              <button onClick={handleDownload} className="bg-[#7F28F8] hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                { integrationTranslations.downloadBtn}
              </button>
            </div>
          </div>
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>2.</span>
            <span>{ integrationTranslations.wstep2}.</span>
            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={firstImage}
                placeholder={
                  <Image
                    preview={false}
                    src={firstImage}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>3.</span>
            <span>{ integrationTranslations.wstep3}</span>
            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={secondImage}
                placeholder={
                  <Image
                    preview={false}
                    src={secondImage}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>4.</span>
            <span>{ integrationTranslations.wstep4}</span>
            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={thirdImage}
                placeholder={
                  <Image
                    preview={false}
                    src={thirdImage}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>5.</span>
            <span>{ integrationTranslations.wstep5}</span>
            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={fourthImage}
                placeholder={
                  <Image
                    preview={false}
                    src={fourthImage}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
         
          <div style={{ padding: '20px' }}>
            <span style={{ fontWeight: 'bold' }}>6.</span>
            <span>{ integrationTranslations.wstep7}</span>
            <div className="image-container" style={{ marginTop: '20px' }}>
              <Image
                width="100%"
                src={fifthImage}
                placeholder={
                  <Image
                    preview={false}
                    src={fifthImage}
                    width="100%"
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </CustomLayout>
  )
}
