import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import {
  Button,
  Row,
  Col,
  Card,
  List,
  Avatar,
  Tag,
  Modal,
  Input,
  Typography,
  message,
  Space,
  Tooltip,
  Popconfirm,
} from "antd";
import {
  UserAddOutlined,
  CrownOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

export default function TeamMembers({
  teamLead,
  teamMembers,
  isLoading,
  fetchTeamData,
  roomChat,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [teamName, setTeamName] = useState("");
  const [isPaying, setIsPaying] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const isShopifyUser = localStorage.getItem('Userdata')?.mode === 'shopify';
  const isInvited = localStorage.getItem("invited") === "true";
  const [LoadingRedirection,setLoadingRedirection]=useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchSubscriptionStatus();
    fetchTeamData();
    checkPaymentStatus();
  }, []);

  // ✅ 1. Fetch subscription status
  const fetchSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/administration/team/stripe/subscription-status/`, {
        headers: { Authorization: `Token ${token}` },
      });

      setSubscriptionActive(response.data.active);
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
  };

  // ✅ 2. Check if payment was completed (when user returns from Stripe)
  const checkPaymentStatus = async () => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");

    if (sessionId) {
      try {
        const response = await axios.post(
          `${config.baseUrl}/administration/team/stripe/verify-payment/`,
          { session_id: sessionId },
          { headers: { Authorization: `Token ${token}` } }
        );

        if (response.data.success) {
          message.success("Subscription activated! You can now add team members.");
          setSubscriptionActive(true);
          navigate(location.pathname, { replace: true });
        }
      } catch (error) {
        message.error("Payment verification failed.");
      }
    }
  };


  // Remove User Function
  const removeUser = async (userId) => {
    try {
      message.loading({ content: "Removing user...", key: "remove" });
      const response = await axios.delete(
        `${config.baseUrl}/administration/team?email=${userId}`,
        
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      if (response.data.success) {
        message.success({ content: "User removed successfully!", key: "remove", duration: 2 });
        fetchTeamData(); // Refresh the team data
        
      } else {
        message.error(response.data.error || "Failed to remove user.");
      }
    } catch {
      message.error("Error removing user.");
    }
  };


  const handlePayment = async () => {
 
    try {
      setIsPaying(true);

      if (isShopifyUser) {
        const shopifyResponse = await axios.post(
          `${config.baseUrl}/administration/team/shopify/billing/`,
          { action: 'invite_member', team_name: teamName,email },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (shopifyResponse.data.url) {
          window.location.href = shopifyResponse.data.url;
        } else {
          message.error('Failed to initiate Shopify billing.');
        }
      } else {
        const stripeResponse = await axios.post(
          `${config.baseUrl}/administration/team/stripe/create-checkout-session/`,
          { },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (stripeResponse.data.url) {
          window.location.href = stripeResponse.data.url;
        } else {
          message.error('Failed to initiate payment.');
        }
      }
    } catch (error) {
      message.error('Error initiating payment.');
    } finally {
      setIsPaying(false);
    }
  };

  const addMember = async () => {  
      
    
    const key = 'updatable';

  
    try {
      message.loading({ content: 'Adding a member...', key });

      const response = await axios.post(
        `${config.baseUrl}/administration/team/`,
        {
          email,
          team_name: teamName,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
  
      if (response.data.message === "Invitation sent successfully") {
        message.success({ content: 'Team member added successfully.!', key, duration: 2 });

        fetchTeamData(); // Refresh team data after success
      } else {
        message.error(response.data.error || 'Failed to add team member.');
      }
    } catch (error) {
      message.error(error.response?.data?.error || 'Failed to add team member.');
    }
  };

  const createCustomerPortal = async () => {
    let userdata = localStorage.getItem('Userdata');
    const userdataVarparsed = JSON.parse(userdata);
  
    setLoadingRedirection(true)
    try {
      // Replace with logic to get customerId from your app's context or state
      const response = await axios.post(`${config.apiUrl}stripe/create-customer-portal-session/`, {
      }, {
        headers: {
            Authorization: `Token ${userdataVarparsed?.token}`,
        }
      });
  
      setLoadingRedirection(false)
      window.location.href = response.data.url;

    } catch (error) {
      console.error('Error creating portal session:', error);
      setLoadingRedirection(false)
    }
  
  };
  

  return (
    <div style={{ padding: "24px" }}>
      {/* Subscription Status */}

      {!isInvited?(
      <Card
      bordered={false}
      style={{
        marginBottom: "24px",
        backgroundColor: subscriptionActive ? "#f6ffed" : "#fff2f0",
      }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <Space>
            {subscriptionActive ? (
              <CheckCircleOutlined style={{ color: "#52c41a", fontSize: "20px" }} />
            ) : (
              <ExclamationCircleOutlined style={{ color: "#ff4d4f", fontSize: "20px" }} />
            )}
            <Title level={5} style={{ margin: 0 }}>
              {subscriptionActive
                ? "Subscription Active: You can add unlimited team members."
                : "Subscription Inactive: Please subscribe with only $5 to add members."}
            </Title>
          </Space>
        </Col>
        {!subscriptionActive && (
          <Col>
            <Button
              type="primary"
              onClick={()=>handlePayment()}
              loading={isPaying}
              style={{ backgroundColor: "#7F28F8", borderColor: "#7F28F8" }}
            >
              Subscribe Now
            </Button>
          </Col>
        )}

         {subscriptionActive && (
          <Col>
            <Button
              type="primary"
              onClick={()=>createCustomerPortal()}
              loading={LoadingRedirection}
              
      
            >
              Manage Subscription
            </Button>
          </Col>
        )}
      </Row>
    </Card>

      ):(
        <></>
      )}

      {/* Header */}

      <div className="flex justify-between items-center mb-4">
        <Title level={3}>Team Members</Title>
      
          {!isInvited?(
          <Tooltip title={subscriptionActive ? "Invite a new team member" : "Subscription required"}>
            <Button
            type="primary"
            icon={<UserAddOutlined />}
            onClick={() => setIsModalOpen(true)}
            disabled={!subscriptionActive}
            style={{
              backgroundColor: subscriptionActive ? "#7F28F8" : "#d9d9d9",
              borderColor: subscriptionActive ? "#7F28F8" : "#d9d9d9",
              color: subscriptionActive ? "#fff" : "#a9a9a9",
            }}
          >
            Invite Member
          </Button>
        </Tooltip>
          ):(
            <></>
          )}
  
      </div>

      {/* Team Members List */}
      <Card>
  <List
    itemLayout="horizontal"
    loading={isLoading}
    dataSource={teamMembers}
    renderItem={(member) => (
      <List.Item
        actions={
          !isInvited // Use external variable directly
            ? [
                <Popconfirm
                  title="Are you sure you want to remove this user?"
                  onConfirm={() => removeUser(member?.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Remove User">
                    <Button danger shape="circle" icon={<DeleteOutlined />} />
                  </Tooltip>
                </Popconfirm>,
              ]
            : []
        }
      >
        <List.Item.Meta
          avatar={
            <Avatar
              size={40}
              src={member.user?.image}
              style={{
                backgroundColor: !member.user?.image ? '#fde3cf' : undefined,
                color: !member.user?.image ? '#f56a00' : undefined,
                fontWeight: 'bold',
              }}
            >
              {!member.user?.image &&
                member.invitation?.[0]?.invitee_email
                  ?.split('@')[0]
                  .split('.')
                  .map((part) => part[0]?.toUpperCase())
                  .join('')
                  .slice(0, 2)}
            </Avatar>
          }
          title={
            <Space>
              <Text>
                {member.user?.email ||
                  member.invitation?.[0]?.invitee_email ||
                  'Invitee Email'}
              </Text>
              <Tag
                color={
                  member.invitation?.[0]?.status === 'accepted'
                    ? 'green'
                    : 'orange'
                }
              >
                {member.invitation?.[0]?.status || 'Pending'}
              </Tag>
            </Space>
          }
        />
   
      </List.Item>
    )}
  />
</Card>

      {/* Team Lead Section */}
      {teamLead && (
        <div style={{ marginTop: "24px" }}>
          <Title level={4} style={{ marginBottom: "16px" }}>
            <CrownOutlined style={{ color: "#FFD700", marginRight: "8px" }} />
            Team Lead
          </Title>
          <div className="flex items-center gap-3">
          <Avatar
              size={64}
              src={teamLead.image}
              style={{
                backgroundColor: !teamLead.image ? '#fde3cf' : undefined, // Background only when no image
                color: !teamLead.image ? '#f56a00' : undefined, // Text color only when no image
                fontWeight: 'bold',
              }}
            >
              {!teamLead.image &&
                teamLead.email
                  ?.split('@')[0] // Use the part of the email before '@'
                  .split('.') // Split by dots (if the email has parts like first.last)
                  .map((part) => part[0]?.toUpperCase()) // Get the first letter of each part
                  .join('') // Join the initials
                  .slice(0, 2)} {/* Limit to 2 characters */}
            </Avatar>

            <Text className="font-medium">{teamLead.email}</Text>
          </div>
        </div>
      )}

      {/* Modal */}
      <Modal
        title="Invite Member"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={addMember} >
            Invite
          </Button>,
        ]}
      >
        {!teamMembers.length && (
          <Input
            placeholder="Enter team name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            className="mb-4"
          />
        )}
        <Input
          placeholder="Enter member's email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Modal>
    </div>
  );
}
