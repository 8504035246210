import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, message,Drawer } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import {
  MailOutlined,
  MessageOutlined,
  ToolOutlined,
  TeamOutlined,
  UserOutlined,
  RobotOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  ShoppingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  WechatWorkOutlined,
  PieChartOutlined,
  WhatsAppOutlined
} from '@ant-design/icons';

import arLogo from '../assets/svg/logo-ar.png';
import enLogo from '../assets/svg/logo.png';
import config from '../config';
import axios from 'axios';
import whatsappLogo from '../assets/whatsappLogo.svg'


const { Header, Content, Sider } = Layout;

const CustomLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(JSON.parse(localStorage.getItem('sidebarCollapsed')) || false);
  const [iconNames, setIconNames] = useState([]);
  const [routePaths, setRoutePaths] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(localStorage.getItem('s_m') || '1');
  const [selectedLang, setSelectedLang] = useState(sessionStorage.getItem('selectedLang') || 'en');
  const [isArabic, setIsArabic] = useState(selectedLang === 'ar');
  const [hoveredItem, setHoveredItem] = useState(null);
  const [logout, setLogout] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const headerTranslation = t("header");
  const [visibleIcons, setVisibleIcons] = useState([])

  const [chatbotCredits, setChatbotCredits] = useState('')
  const [whatsappCredits, setWhatsappCredits] = useState('')

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const isInvited = localStorage.getItem("invited") === "true";


  useEffect(() => {
    
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${(localStorage.getItem('token'))}`,
                'Accept': 'application/json'
            }
        };

        axios.get(`${config.apiUrl}fetch-remaining-credit/`, headers).then((res)=>{
          if (chatbotCredits !== res.data.remaing_credits.chatbot_credits || whatsappCredits !== res.data.remaing_credits.whatsapp_credits) {
            setChatbotCredits(res.data.remaing_credits.chatbot_credits)
            setWhatsappCredits(res.data.remaing_credits.whatsapp_credits)
          }
        })

  },[])


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('UserObject'));
    const isInvited = localStorage.getItem("invited") === "true"; // Check if user is invited

    setChatbotCredits(user?.chatbot_credit || 0);
    setWhatsappCredits(user?.whatsapp_credit || 0);

    const newIconNames = [];
    const newRoutePaths = [];
    const newIconComponents = [];

    if (isInvited) {
        // User is invited, show restricted menu
        newIconNames.push('analytics', 'message', 'accSetting', 'team');
        newRoutePaths.push('/user/analytics', '/user/messages/', '/user/edit-profile', '/user/Adminusers');
        newIconComponents.push(PieChartOutlined, MessageOutlined, UserOutlined, TeamOutlined);
    } else {
        // User is not invited, show the full menu
        newIconNames.push(
            'botTraining',
            'integration',
            'customization',
            'leads',
            'analytics',
            'message',
            'accSetting',
            'help'
        );
        newRoutePaths.push(
            '/user/settings',
            '/user/integration',
            '/user/chatbot',
            '/user/leads',
            '/user/analytics',
            '/user/messages/',
            '/user/edit-profile',
            '/user/help'
        );
        newIconComponents.push(
            RobotOutlined,
            ToolOutlined,
            WechatWorkOutlined,
            MailOutlined,
            PieChartOutlined,
            MessageOutlined,
            UserOutlined,
            QuestionCircleOutlined
        );

        // Add Shopify-specific menu item if user is a Shopify user
        if (user?.mode === "shopify") {
            newIconNames.push('shopify');
            newRoutePaths.push('/user/shopify');
            newIconComponents.push(ShoppingOutlined);
        } else {
            // Only add "team" for non-Shopify users
            newIconNames.push('team');
            newRoutePaths.push('/user/Adminusers');
            newIconComponents.push(TeamOutlined);
        }
    }

    setIconNames(newIconNames);
    setRoutePaths(newRoutePaths);
    setVisibleIcons(newIconComponents); // Update menu items
}, []);

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', collapsed);
  }, [collapsed]);

  const handleMenuClick = (e) => {
    if (!logout) {
      localStorage.setItem('s_m', e.key);
      setSelectedMenuItem(e.key);
    }
    if (isMobile) {
      setDrawerVisible(false);
    }
  };

  const handleMouseEnter = (key) => {
    setHoveredItem(key);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || 'en';
    setSelectedLang(lang);
  
  }, []);

  useEffect(() => {
    setIsArabic(selectedLang === 'ar');
    sessionStorage.setItem('selectedLang', selectedLang);
  }, [selectedLang]);


  const handleLogout = () => {
    setLogout(true);
    localStorage.clear();
    message.error({ content: 'Successfully Logged out', duration: 4 });
    setTimeout(() => {
      navigate('/auth/login');
    }, 3000);
  };

  const shouldRenderHeader = () => {
    return !location.pathname.includes('/user/messages/');
  };

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      console.log('Is mobile:', mobile);
      setIsMobile(mobile);
      if (!mobile) {
        setDrawerVisible(false); // Close the drawer on switching to desktop
      }
    };
  
    handleResize(); // Initialize
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  


  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };
  

  
  const items = iconNames.map((name, index) => {
    const IconComponent = visibleIcons[index] || UserOutlined; // Access the 1D array
  
    if (!IconComponent) {
      console.warn(`Icon component for ${name} is undefined`);
      return null; // Skip rendering this item if the component is undefined
    }
  
    // console.log(`Rendering icon for ${name}:`, IconComponent);
  
    return {
      key: String(index + 1),
      icon: React.createElement(IconComponent),
      label: t(`navbar.${name}`),
      path: routePaths[index],
    };
  });
  return (
<Layout style={{ minHeight: '100vh' }}>
  {isMobile ? (
    <Drawer
      title={<img src={selectedLang === 'ar' ? arLogo : enLogo} alt="Logo" />}
      placement={isArabic ? 'right' : 'left'}
      closable
      onClose={toggleDrawer}
      open={drawerVisible}
    >
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[selectedMenuItem]}
        onClick={(e) => {
          handleMenuClick(e);
          toggleDrawer(); // Close Drawer after clicking a menu item
        }}
      >
        {items.map((item) => (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link to={item.path}>{item.label}</Link>
          </Menu.Item>
        ))}
          <Menu.Item onClick={handleLogout} icon={<LogoutOutlined/>}>
          <h2 className={`mb-0 ${collapsed ? 'hidden' : ''}`}>{t('navbar.logout')}</h2>
          </Menu.Item>
      </Menu>

    </Drawer>
  ) : (
    <Sider
    trigger={null}
    collapsible
    collapsed={collapsed}
    style={{
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      right: isArabic ? 0 : 'auto',
      left: isArabic ? 'auto' : 0,
      backgroundColor: '#7F28F8',
    }}
    breakpoint='md'
  >
    <div className="demo-logo-vertical">
      <img className='' alt='company-alt' src={ selectedLang==='ar' ? arLogo : enLogo} />
    </div>
    <Menu
        theme='transparent'
        style={{ color: 'white', marginTop: '40px', fontSize: 15 }}
        mode="inline"
        selectedKeys={[selectedMenuItem]}
        onClick={handleMenuClick}

        defaultSelectedKeys={['1']}
>
      {items.map((item) => (
        <Menu.Item
          key={item.key}
          icon={item.icon}
          style={{ backgroundColor: hoveredItem === item.key ? '#9E41FF' : 'transparent' }}
          onMouseEnter={() => handleMouseEnter(item.key)}
          onMouseLeave={handleMouseLeave}
        >
          {item.path ? (
            <Link to={item.path}>{item.label}</Link>
          ) : (
            <>{item.label}</>
          )}
        </Menu.Item>
      ))}
    </Menu>
    <div className="logoutbtn flex gap-3 cursor-pointer" onClick={handleLogout}>
      <LogoutOutlined style={{ display: collapsed ? 'block' : 'block' }} className='cursor-pointer' />
      <h2 className={`mb-0 ${collapsed ? 'hidden' : ''}`}>{t('navbar.logout')}</h2>
    </div>
  </Sider>
  )}
  <Layout
    className="site-layout"
    style={{
      marginLeft: isMobile ? 0 : collapsed ? 80 : 200,
      marginRight: isMobile ? 0 : isArabic ? (collapsed ? 80 : 200) : 'auto',
    }}
  >
    <Header
            className="site-layout-background"
            style={{
              padding: 0,
              height: "55px",
              background: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}

    >
  {isMobile ? (
    // Mobile Header
    <div className='flex ml-10  justify-between gap-36'>
      <div>
      <Button
        type="text"
        icon={<MenuFoldOutlined />}
        onClick={toggleDrawer}
        style={{
          fontSize: '16px',
        }}
      />
      </div>
        <div>

        <LanguageSelector setSelectedLang={setSelectedLang} />
        </div>

       
      </div>
  ) : (
    // Desktop Header (exactly as provided)
    <>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={toggleCollapsed}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />

            <div style={{ display: 'flex' }}>
            {!isInvited && (
              <>
                <Button
                  style={{
                    margin: '15px 10px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: '#000',
                    cursor: 'not-allowed',
                    opacity: 0.6,
                    borderRadius: '40px',
                    fontWeight: "bold",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <WhatsAppOutlined style={{fontSize:25}}/>
                   {whatsappCredits}
                </Button>

                <Button
                  style={{
                    margin: '15px 8px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: '#000',
                    cursor: 'not-allowed',
                    opacity: 0.6,
                    borderRadius: '40px',
                    fontWeight: "bold",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <MessageOutlined style={{fontSize:25}}/>
                   {chatbotCredits}
                </Button>
              </>
            )}
              <div className='mb-9'>

              <LanguageSelector  setSelectedLang={setSelectedLang} />

              </div>
            </div>
    </>
  )}
</Header>

    <Content
      style={{
        marginTop: '20px', // Push content below header
        backgroundColor: '#fff',
        overflow: 'auto',
        width:'100%'
      }}
    >
      <div
        style={{
          minHeight: 'calc(100vh - 55px)', // Account for header height
          padding: '10px',
          backgroundColor: '#fff',
          width:'100%'

        }}
      >
        {children}
      </div>
    </Content>
  </Layout>
</Layout>


  );
  
  
};

export default CustomLayout;