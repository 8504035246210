import { useNavigate } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "./CSS/Pricing.css";
import colorJawebLogo from "../../assets/Onboarding/colorLogo.png";

export default function Pricing() {
  const navigate = useNavigate();

  const webAccessData = {
    name: "Web Access",
    description: "Everything you need to run on the web",
    price: "$19.99/month", // Monthly price
    yearlyPrice: "$199.99/year", // Yearly price
    benefits: [
      "Access to a professional dashboard with analytics",
      "Zapier Integration",
      "WhatsApp integration",
      "Real-time messaging",
      "Calendly integration",
      "Tracking of orders",
      "Cart Overview",
      "Answering questions about products",
    ],
    credits: [
      "10,000 chatbot credits every month",
      "12,500 WhatsApp credits every month",
    ],
    buttonText: "31-days free trial",
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Top Section with Highlight */}
      <div className="py-8 px-4">
        <div className="max-w-6xl mx-auto flex items-center">
          <img src={colorJawebLogo} className="w-40" alt="Jaweb Logo" />
        </div>
      </div>

      {/* Pricing Section */}
      <div className="max-w-6xl mx-auto px-4 py-10">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-6">
          Choose Your Plan
        </h2>
        <p className="text-center text-gray-600 mb-10">
          Unlock the full potential of your business with our plans tailored
          just for you.
        </p>
        <div className="grid gap-8 md:grid-cols-2">
          {/* Monthly Pricing Card */}
          <div className="bg-white border border-gray-200 rounded-xl shadow-lg p-6 hover:scale-105 transition-transform">
            <h3 className="text-xl font-bold text-gray-800">
              {webAccessData.name} - Monthly
            </h3>
            <p className="text-sm text-gray-600 mt-2">
              {webAccessData.description}
            </p>

            <div className="price my-6 text-4xl font-extrabold text-[#a855f7]">
              <span>{webAccessData.price}</span>
            </div>

            <div className="features">
              {webAccessData.benefits.map((benefit, idx) => (
                <div
                  key={idx}
                  className="flex items-center gap-2 my-2 text-sm text-gray-700"
                >
                  {idx >= 5 ? (
                    <CloseOutlined className="text-red-500" />
                  ) : (
                    <CheckOutlined className="text-green-500" />
                  )}
                  <span>{benefit}</span>
                </div>
              ))}

              {/* Credits Section */}
              {webAccessData.credits.map((credit, idx) => (
                <div
                  key={`credit-${idx}`}
                  className="flex items-center gap-2 my-2 text-sm text-gray-700 mt-4"
                >
                  <span >*{credit}</span>
                </div>
              ))}
            </div>

            <button
              onClick={() =>
                navigate("/auth/signup?plan=monthly", {
                  state: { MakeStepToZero: true },
                })
              }
              className="mt-6 bg-[#a855f7] text-white rounded-lg px-6 py-3 hover:bg-[#9333ea] transition-all w-full text-lg font-semibold"
            >
              {webAccessData.buttonText}
            </button>
          </div>

          {/* Yearly Pricing Card */}
          <div className="bg-white border border-gray-200 rounded-xl shadow-lg p-6 hover:scale-105 transition-transform">
            <h3 className="text-xl font-bold text-gray-800">
              {webAccessData.name} - Yearly
            </h3>
            <p className="text-sm text-gray-600 mt-2">
              {webAccessData.description} with <b>5% off</b>
            </p>

            <div className="price my-6 text-4xl font-extrabold text-[#a855f7]">
              <span>{webAccessData.yearlyPrice}</span>
            </div>

            <div className="features">
              {webAccessData.benefits.map((benefit, idx) => (
                <div
                  key={idx}
                  className="flex items-center gap-2 my-2 text-sm text-gray-700"
                >
                  {idx >= 5 ? (
                    <CloseOutlined className="text-red-500" />
                  ) : (
                    <CheckOutlined className="text-green-500" />
                  )}
                  <span>{benefit}</span>
                </div>
              ))}

              {/* Credits Section */}
              {webAccessData.credits.map((credit, idx) => (
                <div
                  key={`credit-${idx}`}
                  className="flex items-center gap-2 my-2 text-sm text-gray-700 mt-4"
                >
                  
                  <span>*{credit}</span>
                </div>
              ))}
            </div>

            <button
              onClick={() =>
                navigate("/auth/signup?plan=yearly", {
                  state: { MakeStepToZero: true },
                })
              }
              className="mt-6 bg-[#a855f7] text-white rounded-lg px-6 py-3 hover:bg-[#9333ea] transition-all w-full text-lg font-semibold"
            >
              {webAccessData.buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
