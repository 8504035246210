import React, { useState, useEffect } from 'react';
import { Row, Col, Menu, message as antdMessage, message,Button } from 'antd';
import { CreditCardOutlined, UserOutlined, LockOutlined, BellOutlined ,BookOutlined, DollarOutlined } from '@ant-design/icons';
import { Link, useLocation as useReactRouterLocation } from 'react-router-dom';
import CustomLayout from '../../../layouts/Customlayout';
import config from '../../../config';
import { useTranslation } from 'react-i18next';
import '../Css/AccountSettings.css'
import { Switch } from 'antd';
import { Modal, Spin } from 'antd'
import tickIcon from '../../../assets/tick.png'
import axios from 'axios';
import Flex from '../../components/shared-components/Flex';
import SettingOption from './MenuItems';
import { useLocation } from 'react-router-dom';




export default function UsersRender() {
    const [creditAmount, setCreditAmount] = useState(1000.00); // Example credit amount
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    const stateMessage = location.state?.message;
  
    const { t } = useTranslation();
    const accSettingTranslations = t("accountSetting");
    const [isChatbotCreditsModalVisible, setIsChatbotCreditsModalVisible] = useState(false);
    const [isWhatsappCreditsModalVisible, setIsWhatsappCreditsModalVisible] = useState(false);
    const [isJawebBrandingRemoved, setIsJawebBrandingRemoved] = useState(false);
    const [chatbotRechargeCredit, setChatbotRechargeCredit] = useState(5000);
    const [chatbotCreditAmount, setChatbotCreditAmount] = useState(19.6);
    const [whatsappRechargeCredit, setWhatsappRechargeCredit] = useState(5000);
    const [whatsappCreditAmount, setWhatsappCreditAmount] = useState(19.6);
    const [PoweredByJaweb,setRemovePoweredByJaweb]= useState(false)
    const [loading, setLoading] = useState(false)
    const [LoadingUpdate,setLoadingUpdate] = useState(false)
    const [LoadingRedirection,setLoadingRedirection]=useState(false)
    const [LoadingPay,setLoadingPay]=useState(false)
  
  
  
  
    const user = JSON.parse(localStorage.getItem('UserObject'));
    const yearlyPriceId=config.yearlyPriceId
    const montlyPriceId=config.montlyPriceId
  
    const priceID= localStorage.getItem('price_id')
  
    const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
  
    const shopify = UserInfo?.mode === 'shopify';
  


    // Extract the charge_id from the URL
    const chargeId = queryParams.get('charge_id');
    const purpose = queryParams.get('purpose');
  
    console.log(chargeId)

    const referredBy = user.referredBy || '';

    // Check if user was referred by Rivolia
    const isRivoliaReferral = referredBy === 'contact@rivolia.pro';

    // Set base prices
    let monthlyPrice = 29.99;
    let yearlyPrice = 299.99;

    // Apply 10% discount for Rivolia referrals
    if (isRivoliaReferral) {
      monthlyPrice = (monthlyPrice * 0.9).toFixed(2); // 10% off
      yearlyPrice = (yearlyPrice * 0.9).toFixed(2); // 10% off
    }

    let webMonthlyPrice=19.99;
    let webYearlyPrice=199.99

    if (isRivoliaReferral) {
      webMonthlyPrice = (webMonthlyPrice * 0.9).toFixed(2); // 10% off
      webYearlyPrice = (webYearlyPrice * 0.9).toFixed(2); // 10% off
    }

      


    useEffect(() => {
      if (chargeId) {
        if (purpose === 'credits') {
          verifyShopifyCreditPurchase(chargeId);
        } else if (purpose === 'brand_removal') {
          verifyShopifyBrandRemovalCharge(chargeId);
        }
      }
    }, [chargeId, purpose]);
  
    useEffect(() => {
      if (stateMessage) {
        antdMessage.warning(stateMessage);
      }
    }, [stateMessage]);
  
    const verifyShopifyBrandRemovalCharge = async (chargeId) => {
      setLoadingPay(true);
  
      try {
        const token = localStorage.getItem('token');
  
        if (!token) {
          message.error('User is not authenticated');
          setLoadingPay(false);
          return;
        }
  
        const headers = {
          Authorization: `Token ${token}`,
        };
  
        // Make the request to verify the charge
        const response = await axios.get(`${config.apiUrl}verify-shopify-charge/?charge_id=${chargeId}`, { headers });
  
        if (response.data.status === 'accepted' || response.data.status === 'active') {
          // If the charge is accepted or active, show success message
          message.success('Payment successful! The charge was completed.');
          fetchChatbotDetails();
          
        } else {
          // If the charge status is anything else, show an error message
          message.error(`Payment failed or is still pending. Status: ${response.data.status}`);
        }
      } catch (error) {
        message.error('Failed to verify payment status');
        console.error('Error verifying charge:', error);
      } finally {
        setLoadingPay(false);
      }
    };
  
    const verifyShopifyCreditPurchase = async (chargeId) => {
      setLoadingPay(true);
    
      try {
        const token = localStorage.getItem('token');
    
        if (!token) {
          message.error('User is not authenticated');
          setLoadingPay(false);
          return;
        }
    
        const headers = {
          Authorization: `Token ${token}`,
        };
    
        // Make the request to verify the credit purchase charge
        const response = await axios.get(`${config.apiUrl}verify-shopify-credit-purchase/?charge_id=${chargeId}`, { headers });
    
        if (response.data.status === 'accepted' || response.data.status === 'active') {
          // If the charge is accepted or active, show success message with updated credit details
          message.success('Credit purchase successful! Credits have been added.');
    
          // Optionally, display updated credit balances if included in response
          const { new_total_chatbot_credits, new_total_whatsapp_credits } = response.data;
          if (new_total_chatbot_credits !== undefined) {
            message.info(`New Chatbot Credits: ${new_total_chatbot_credits}`);
          }
          if (new_total_whatsapp_credits !== undefined) {
            message.info(`New WhatsApp Credits: ${new_total_whatsapp_credits}`);
          }
    
          // Optionally refresh or fetch updated details
          fetchChatbotDetails();
        } else {
          // If the charge status is anything else, show an error message
          message.error(`Credit purchase failed or is still pending. Status: ${response.data.status}`);
        }
      } catch (error) {
        message.error('Failed to verify payment status');
        console.error('Error verifying charge:', error);
      } finally {
        setLoadingPay(false);
      }
    };
    
  
  
    useEffect(() => {
      if (stateMessage) {
        antdMessage.warning(stateMessage);
      }
    }, [stateMessage]);
  
   
    const MenuItem = ({ icon, path, label }) => (
      <>
        {icon}
        <span>{label}</span>
        <Link to={path} />
      </>
    );
  
  
  
    const handleChatbotCreditsModalOK = async () => {
      setLoading(true);
    
      try {
        const headers = {
          Authorization: `Token ${localStorage.getItem('token')}`,
        };
    
        const params = {
          amount: chatbotCreditAmount,
          credit_type: 'chatbot',
          total_credits: chatbotRechargeCredit,
        };
    
        const endpoint = shopify
          ? `${config.apiUrl}add-credits-shopify-view/`
          : `${config.apiUrl}add-credits-view/`;
    
        const response = await axios.get(endpoint, { headers, params });
    
        if (shopify && response.data.return_url) {
          // Redirect Shopify user to the confirmation URL
          window.location.href = response.data.return_url;
        } else if (!shopify && response.data.session_id) {
          // For non-Shopify users, initiate Stripe Checkout
          const stripe = window.Stripe(config.Publishable_key);
          const { error } = await stripe.redirectToCheckout({ sessionId: response.data.session_id });
          if (error) message.error('There was an error processing the payment');
        }
      } catch (e) {
        message.error('Payment error: ' + e.message);
      } finally {
        setLoading(false);
        setIsChatbotCreditsModalVisible(false);
      }
    };
    
  
    useEffect(() => {
      const fetchSessionStatus = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
  
        const headers = {
          Authorization: `Token ${localStorage.getItem('token')}`, 
        };
  
        const params = {
            session_id:sessionId,
        };
  
        if (sessionId) {
          try {
            const response = await axios.get(`${config.apiUrl}verify-credit-payment/`, { headers:headers,params:params });
            if (response.data) {
              if (response.status != 201 && response.data == true) {
                message.success('Payment successful! The credits added.');
              }
            } else {
              console.log('Payment verification failed.');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        } else {
          console.log('No session ID found.');
        }
      };
  
      fetchSessionStatus();
    }, []);
  
  
    const handleChatbotCreditsModalClose = () => {
      setIsChatbotCreditsModalVisible(false);
    };
  
    useEffect(() => {
      setChatbotCreditAmount(Number((chatbotRechargeCredit * 3.92)/1000).toFixed(2))
    },[chatbotRechargeCredit])
  
  
    const handleWhatsappCreditsModalOK = async () => {
      setLoading(true);
    
      try {
        const headers = {
          Authorization: `Token ${localStorage.getItem('token')}`,
        };
    
        const params = {
          amount: whatsappCreditAmount,
          credit_type: 'whatsapp',
          total_credits: whatsappRechargeCredit,
        };
    
        const endpoint = shopify
          ? `${config.apiUrl}add-credits-shopify-view/`
          : `${config.apiUrl}add-credits-view/`;
    
        const response = await axios.get(endpoint, { headers, params });
    
        if (shopify && response.data.return_url) {
          console.log("HERE")
          window.location.href = response.data.return_url;
        } else if (!shopify && response.data.session_id) {
          // For non-Shopify users, initiate Stripe Checkout
          const stripe = window.Stripe(config.Publishable_key);
          const { error } = await stripe.redirectToCheckout({ sessionId: response.data.session_id });
          if (error) message.error('There was an error processing the payment');
        }
      } catch (e) {
        message.error('Payment error: ' + e.message);
      } finally {
        setLoading(false);
        setIsWhatsappCreditsModalVisible(false);
      }
    };
    
  
    const handleWhatsappCreditsModalClose = () => {
      setIsWhatsappCreditsModalVisible(false);
    };
  
    useEffect(() => {    
      setWhatsappCreditAmount(Number((whatsappRechargeCredit * 4.9)/1000).toFixed(2))
    },[whatsappRechargeCredit])
  
  
    const handleBrandingToggle = (option) => { 
      console.log('jaweb branding toggle', option);
      setIsJawebBrandingRemoved(option);
    }
  
  
    const fetchChatbotDetails = async () => {
      setLoadingPay(true);
      try {
        const company_name = JSON.parse(localStorage.getItem('UserObject')).username;
    
        const headers = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        };
    
        const response = await axios.get(`${config.apiUrl}chatbot-details/`, {
          params: { username: company_name },
          headers
        });
    
        const resData = response.data.data;
    
  
        setRemovePoweredByJaweb(resData.remove_powered_by_jaweb);
    
    
        setLoadingPay(false);
  
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingPay(false);
  
      }
    };
    useEffect(() => {
      fetchChatbotDetails();
    }, []);
  
    const HandlePayNowShopify = async () => {
      setLoadingPay(true);
      try {
          // Retrieve the token from localStorage
          const token = localStorage.getItem('token');
  
          if (!token) {
              message.error('User is not authenticated');
              setLoadingPay(false);
              return;
          }
  
          // Define headers with the token
          const headers = {
              Authorization: `Token ${token}`, // Send the token in the Authorization header
          };
  
          // Make the request to your Django API to create the Shopify charge
          const response = await axios.get(`${config.apiUrl}remove-powered-by-jaweb-shopify/`, { headers });
  
          if (response.data && response.data.return_url) {
              // Redirect to the Shopify payment page
              window.location.href = response.data.return_url;
          } else {
              message.error('Failed to get Shopify checkout URL');
          }
      } catch (error) {
          message.error('Failed to create Shopify checkout session');
          console.error('Error:', error);
      } finally {
          setLoadingPay(false);
      }
  };
  
  const handlePayNow = async () => {
    setLoadingPay(true);
    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem('token');
  
      if (!token) {
        message.error('User is not authenticated');
        setLoadingPay(false);
        return;
      }
  
      // Define headers with the token
      const headers = {
        Authorization: `Token ${token}`, // Send the token in the Authorization header
      };
  
      // Make the request with headers
      const response = await axios.get(`${config.apiUrl}remove-powered-by-jaweb/`, { headers });
  
      const sessionId = response.data.id;
      const stripe = window.Stripe(config.Publishable_key); // Replace with your Stripe publishable key
  
      if (sessionId) {
        // Redirect to Stripe Checkout
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });
  
        if (error) {
          message.error('There was an error processing the payment');
        }
      }
    } catch (error) {
      message.error('Failed to create checkout session');
      console.error('Error:', error);
    } finally {
      setLoadingPay(false);
    }
  };
  
  
  const redirectToCustomerPortal = async () => {
    setLoading(true);
    let userdata = localStorage.getItem('Userdata');
    const userdataVarparsed = JSON.parse(userdata);
  
    setLoadingRedirection(true)
    try {
      // Replace with logic to get customerId from your app's context or state
      const response = await axios.post(`${config.apiUrl}stripe/create-customer-portal-session/`, {
      }, {
        headers: {
            Authorization: `Token ${userdataVarparsed?.token}`,
        }
      });
  
      setLoadingRedirection(false)
  
  
      // Redirect to the Stripe Customer Portal
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating portal session:', error);
      setLoadingRedirection(false)
    }
  
    setLoading(false);
  };
  
  
  const yearlyPlanUpgrade = async () => {
    let userdata = localStorage.getItem('Userdata');
    const userdataVarparsed = JSON.parse(userdata);
    console.log(userdataVarparsed.token)
    try {
   
  
      if (!userdataVarparsed?.token) {
        message.error("Access Token not found");
        return;
      }
  
      // Call the backend to create a yearly billing plan
      const response = await axios.post(
        `${config.apiUrl}shopify/subscription/upgrade`,
        {}, // Body of the POST request (if there is no body, pass an empty object)
        {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          }
        }
      );
  
      if (response.data.confirmation_url) {
        // Redirect to Shopify confirmation URL
        window.location.href = response.data.confirmation_url;
      } else {
        message.error(response.data.message || 'Upgrade failed');
      }
    } catch (error) {
      console.error("Upgrade error:", error);
      message.error('An error occurred while upgrading the plan');
    }
  };
  
  // Function to handle Shopify plan downgrade (Monthly)
  const monthlyPlanDowngrade = async () => {
    let userdata = localStorage.getItem('Userdata');
    const userdataVarparsed = JSON.parse(userdata);
    try {
      const shop = localStorage.getItem('shop');
      const accessToken = localStorage.getItem('accessToken');
  
      if (!userdataVarparsed?.token) {
        message.error(" Access Token not found");
        return;
      }
  
      // Call the backend to downgrade to a monthly billing plan
      const response = await axios.post(
        `${config.apiUrl}shopify/subscription/downgrade`,
        {}, // Body of the POST request (if there is no body, pass an empty object)
        {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          }
        }
      );
  
      if (response.data.confirmation_url) {
        // Redirect to Shopify confirmation URL
        window.location.href = response.data.confirmation_url;
      } else {
        message.error(response.data.message || 'Downgrade failed');
      }
    } catch (error) {
      console.error("Downgrade error:", error);
      message.error('An error occurred while downgrading the plan');
    }
  };
  
  
  

  return (
    <div>
    <div >     


<div className='membership-plan-cards'>

  { JSON.parse(localStorage.getItem('UserObject')).mode == 'shopify'?
(
  <>
      <div className="pricing-card">
      <div className="card-header">
          <h3>{ accSettingTranslations.plans.shopifyPlan.shopifyAccess}</h3>
            <span className='price-font price-font-bold'>
              <p className='price-font-style'>$</p>
                {monthlyPrice}                     
            </span>
        </div>
        
        <div className='billing-options'>
          <span style={{ opacity: 0.6, fontSize: '18px', color: 'gray' }}>{ accSettingTranslations.plans.shopifyPlan.perMonth}</span>
        </div>


          <hr />
        <ul className="features">
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature1}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature2}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature3}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature4}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature5}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature6}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature7}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature8}</li>
            </div>
        </ul>

        {localStorage.getItem('shopify_plan')==="monthly" ? (
            <button className="current-button" style={{ width: '100%' }}>
              {accSettingTranslations.plans.webPlan.current_plan}
            </button>
          ) : (
            <button
              onClick={monthlyPlanDowngrade}
              className="upgrade-button"
              style={{ width: '100%' }}
            >
              {localStorage.getItem('shopify_plan') === null ? accSettingTranslations.plans.webPlan.upgrade : accSettingTranslations.plans.webPlan.switch_back}
            </button>
          )}

        </div>


    <div className="pricing-card">
      <div className="card-header">
          <h3>{ accSettingTranslations.plans.shopifyPlan.shopifyAccessYearly}</h3>
            <span className='price-font price-font-bold'>
              <p className='price-font-style'>$</p>
                {yearlyPrice}                 
            </span>

        </div>
        
        <div className='billing-options'>
          <span style={{ opacity: 0.6, fontSize: '18px', color: 'gray' }}>{ accSettingTranslations.plans.shopifyPlan.perYear}</span>
        </div>


          <hr />
        <ul className="features">
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature1}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature2}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature3}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature4}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature5}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature6}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature7}</li>
          </div>
          <div >
            <img src={tickIcon}  className='tick-icon' />
            <li>{accSettingTranslations.plans.shopifyPlan.features.feature8}</li>
            </div>
        </ul>


        {localStorage.getItem('shopify_plan')==="yearly" ? (
            <button className="current-button" style={{ width: '100%' }}>
              {accSettingTranslations.plans.webPlan.current_plan}
            </button>
          ) : (
              <button
                onClick={yearlyPlanUpgrade}
                className="upgrade-button"
                style={{ width: '100%' }}
              >
                {localStorage.getItem('shopify_plan') === null ? accSettingTranslations.plans.webPlan.upgrade : accSettingTranslations.plans.webPlan.upgrade}
              </button>
          )}

    </div>

    </>
   ):(

  <>

<div className="pricing-card">
<div className="card-header">
<h3>{accSettingTranslations.plans.webPlan.webAccess }</h3>
<span className='price-font price-font-bold'>
  <p className='price-font-style'>$</p>
  {webMonthlyPrice}                    
</span>
</div>

<div className='billing-options'>
<span style={{ opacity: 0.6, fontSize: '18px', color: 'gray' }}>{ accSettingTranslations.plans.webPlan.perMonth}</span>
</div>


<hr />
<ul className="features">
<div >
<img src={tickIcon}  className='tick-icon' />
<li>{accSettingTranslations.plans.webPlan.features.feature1}</li>
</div>
<div >
<img src={tickIcon}  className='tick-icon' />
<li>{accSettingTranslations.plans.webPlan.features.feature2}</li>
</div>
<div>
<img src={tickIcon}  className='tick-icon' />
<li>{accSettingTranslations.plans.webPlan.features.feature3}</li>
</div>
<div >
<img src={tickIcon}  className='tick-icon' />
<li>{accSettingTranslations.plans.webPlan.features.feature4}</li>
</div>
<div >
<img src={tickIcon}  className='tick-icon' />
<li>{accSettingTranslations.plans.webPlan.features.feature5}</li>
</div>
<div >
<img src={tickIcon}  className='tick-icon' />
<li>{accSettingTranslations.plans.webPlan.features.feature6}</li>
</div>
<div >
<img src={tickIcon}  className='tick-icon' />
<li>{accSettingTranslations.plans.webPlan.features.feature7}</li>
</div>
</ul>


{priceID === montlyPriceId ? (
<button className="current-button" style={{ width: '100%' }}>
{accSettingTranslations.plans.webPlan.current_plan}
</button>
) : (
<button
onClick={redirectToCustomerPortal}
className="upgrade-button"
style={{ width: '100%' }}
>
{priceID === null ? accSettingTranslations.plans.webPlan.upgrade : accSettingTranslations.plans.webPlan.switch_back}
</button>
)}


</div>


  <div className="pricing-card">
  <div className="card-header">
  <h3>{accSettingTranslations.plans.webPlan.webAccessYearly }</h3>
  <span className='price-font price-font-bold'>
    <p className='price-font-style'>$</p>
    {webYearlyPrice}                   
  </span>
  </div>

  <div className='billing-options'>
  <span style={{ opacity: 0.6, fontSize: '18px', color: 'gray' }}>{ accSettingTranslations.plans.webPlan.perYear}</span>
  </div>
  <hr />
  <ul className="features">
  <div >
  <img src={tickIcon}  className='tick-icon' />
  <li>{accSettingTranslations.plans.webPlan.features.feature1}</li>
  </div>
  <div >
  <img src={tickIcon}  className='tick-icon' />
  <li>{accSettingTranslations.plans.webPlan.features.feature2}</li>
  </div>
  <div>
  <img src={tickIcon}  className='tick-icon' />
  <li>{accSettingTranslations.plans.webPlan.features.feature3}</li>
  </div>
  <div >
  <img src={tickIcon}  className='tick-icon' />
  <li>{accSettingTranslations.plans.webPlan.features.feature4}</li>
  </div>
  <div >
  <img src={tickIcon}  className='tick-icon' />
  <li>{accSettingTranslations.plans.webPlan.features.feature5}</li>
  </div>
  <div >
  <img src={tickIcon}  className='tick-icon' />
  <li>{accSettingTranslations.plans.webPlan.features.feature6}</li>
  </div>
  <div >
  <img src={tickIcon}  className='tick-icon' />
  <li>{accSettingTranslations.plans.webPlan.features.feature7}</li>
  </div>
  </ul>


  {priceID === yearlyPriceId ? (
  <button className="current-button" style={{ width: '100%' }}>
  {accSettingTranslations.plans.webPlan.current_plan}
  </button>
  ) : (
  <button
  onClick={redirectToCustomerPortal}
  className="upgrade-button"
  style={{ width: '100%' }}
  >
  {priceID === null ? accSettingTranslations.plans.webPlan.upgrade : accSettingTranslations.plans.webPlan.upgrade}
  </button>
  )}

  </div>
  </>
  )                  
  }

   

  

  
 </div>
</div>


{JSON.parse(localStorage.getItem('UserObject')).mode == 'email'? (
    <div className="extra-message-credits">
    <p className='chatbot-credit'>{accSettingTranslations?.plans.stripe?.heading}</p>
      <hr />
      <p className="price-info"> {accSettingTranslations?.plans.stripe?.description}</p>

      <button style={{marginLeft:'15px'}} loading={LoadingRedirection} className='upgrade-button' onClick={redirectToCustomerPortal} > <span className='text-center'>{accSettingTranslations?.plans.stripe?.managebtn}</span></button>

    </div>
  ) : (
     <></>
  )


}

<div className="extra-message-credits">
<p className='chatbot-credit'>{accSettingTranslations.plans.credits.chatbot.heading}</p>
  <hr />
  <p className="price-info"><b>$19.6</b> {accSettingTranslations.plans.credits.chatbot.per} <b>5000</b> {accSettingTranslations.plans.credits.chatbot.subHeading} </p>

  <button className='upgrade-button' style={{marginLeft:'15px'}} onClick={()=> setIsChatbotCreditsModalVisible(true)}>{accSettingTranslations.plans.credits.upgradeBtn}</button>
  
  <Modal
    title={accSettingTranslations.plans.credits.chatbot.modalHeading}
    visible={isChatbotCreditsModalVisible}
    onOk={handleChatbotCreditsModalOK}
  onCancel={handleChatbotCreditsModalClose} 
  min={5000}
>
  
  {loading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Spin style={{ fontSize: 40 }} />
    </div>
  ) : (
      <>
        <p>{ accSettingTranslations.plans.credits.chatbot.modalsubHeading}</p>
        <input 
          type="number" 
          value={chatbotRechargeCredit} 
          onChange={(e) => setChatbotRechargeCredit(e.target.value)} 
        />

        <hr style={{backgroundColor:'#cccccc42', border:'None', height:'2px'}}/>
        <h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>${ chatbotCreditAmount}</h3>
        <p>{accSettingTranslations.plans.credits.chatbot.modalPerCharge}</p>
      </>
  )}
  </Modal>
</div>






<div className="extra-message-credits">
  <p className='chatbot-credit'>{accSettingTranslations.plans.credits.whatsapp.heading}</p>
  <hr />
  <p className="price-info"><b>$24.5</b> {accSettingTranslations.plans.credits.chatbot.per} <b>5000</b> {accSettingTranslations.plans.credits.whatsapp.subHeading}</p>

  <button className='upgrade-button' style={{marginLeft:'15px'}} onClick={()=> setIsWhatsappCreditsModalVisible(true)}>{accSettingTranslations.plans.credits.upgradeBtn}</button>
  <Modal
    title={accSettingTranslations.plans.credits.whatsapp.modalHeading}
    visible={isWhatsappCreditsModalVisible}
    onOk={handleWhatsappCreditsModalOK}
    onCancel={handleWhatsappCreditsModalClose} 
>
  
  {loading ? (
    // <Spin style={{ fontSize: 40 }} /> 
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Spin style={{ fontSize: 40 }} />
    </div>
  ) : (<>
  <p>{accSettingTranslations.plans.credits.whatsapp.modalsubHeading}</p>
  <input 
    type="number" 
    value={whatsappRechargeCredit} 
    onChange={(e) => setWhatsappRechargeCredit(e.target.value)} 
    />

  <hr />
  <h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>${ whatsappCreditAmount}</h3>
  <p>{accSettingTranslations.plans.credits.whatsapp.modalPerCharge}</p>
    </>
  )}
  </Modal>
</div>

<div className="extra-message-credits" style={{height:'29vh'}}>
  <p className='chatbot-credit'>{accSettingTranslations.plans.credits.branding.heading}</p>
  <hr />
  <p className="price-info font-bold">$19</p>
  <p className="price-info">{accSettingTranslations.plans.credits.branding.subHeading}</p>



  {
  !PoweredByJaweb ? (
    <Flex className="w-[100%] justify-between items-center font-weight-500">
      <div>
        <Button 
          onClick={user.mode === "shopify" ? HandlePayNowShopify : handlePayNow}
          disabled={LoadingPay}
          loading={LoadingPay}
          type="primary"
          className="flex items-center bg-purple-700"
        >
          <CreditCardOutlined className="text-white" />
          <span className="text-white">
            {accSettingTranslations.plans.credits.branding.pay}
          </span>
        </Button>
      </div>
    </Flex>
  ) : (
    <div className="upgrade-button" style={{marginLeft:'15px'}}>
      <span>{accSettingTranslations.plans.credits.branding.paid}</span>
    </div>
  )
}


</div>
    </div>
  )
}
