import React, { useState } from "react";
import { Link } from "react-router-dom";
import { message } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import config from "../../config";

const AffiliateLogin = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    console.log(`${config.baseUrl}/affiliate/login`);

    try {
      const response = await fetch(`${config.baseUrl}/affiliate/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Login failed. Please check your email and password.");
      }

      const data = await response.json();
      message.success("Login successful! Redirecting to dashboard...");

      // Store token or session info
      localStorage.setItem("affiliateToken", data.token);

      // Redirect to dashboard
      window.location.href = "/program/affiliate/dashboard";
    } catch (err) {
      message.error(err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h1 className="text-3xl font-bold mb-2 text-center">Jaweb</h1>
        <h2 className="text-2xl font-bold mb-4 text-center">Affiliate Login</h2>
        <h3 className="text-sm text-center mb-4">
          Welcome back to <span className="font-bold text-blue-500">Jaweb</span>
        </h3>

        {error && <div className="text-red-500 mb-4">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div className="mb-4 relative">
            <label className="block text-sm font-medium mb-1">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-2 border rounded pr-10"
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-600"
              >
                {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className={`w-full p-2 rounded text-white ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500"
            }`}
            disabled={loading}
          >
            {loading ? "Signing in..." : "Sign In"}
          </button>

          <p className="text-sm text-gray-500 text-center mt-4">
            Thank you for joining the Jaweb Affiliate Program. We look forward to a successful partnership!
          </p>

          <p className="text-center text-sm mt-4">
            Don't have an account?{" "}
            <Link to="/program/affiliate/signup" className="text-blue-500 underline">
              Sign up here
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default AffiliateLogin;
