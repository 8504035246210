import React, { useState, useEffect } from 'react';
import { Card, Tabs, message } from 'antd';
import {
  DashboardOutlined,
  MessageOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import CustomLayout from '../../layouts/Customlayout';
import config from '../../config';
import TeamChat from './TeamChat';
import Overview from './Overview';
import TeamMembers from './TeamMembers';

const { TabPane } = Tabs;

export default function TeamDashboard() {
  const [teamMembers, setTeamMembers] = useState([]);
  const token = localStorage.getItem('token');
  const [roomChat, setRoomChat] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [teamLead, setTeamLead] = useState('');
  const [currentTab, setCurrentTab] = useState('4'); // State to track the active tab

  // Fetch team members on component load
  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${config.baseUrl}/administration/team/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      console.log(response.data)
      setRoomChat(response.data[0]?.room_chat);
      setTeamMembers(response.data[0]?.memberships || []);
      setTeamLead(response.data[0]?.team_lead || null);
    } catch (error) {
      message.error('Failed to load team members.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to switch to the Team tab for payment verification
  const switchToTeamTab = () => {
    setCurrentTab('4'); // Set the active tab to the Team tab (key "4")
  };

  return (
    <CustomLayout>
      <div className="p-4 space-y-6">
        <Tabs
          activeKey={currentTab} // Bind the active tab to the currentTab state
          onChange={(key) => setCurrentTab(key)} // Update the current tab on user interaction
          className="mb-4"
        >
          <TabPane
            tab={
              <span>
                <DashboardOutlined /> Overview
              </span>
            }
            key="1"
          >
            <Overview />
          </TabPane>
          <TabPane
            tab={
              <span>
                <TeamOutlined /> Team
              </span>
            }
            key="4"
          >
            <TeamMembers
              teamMembers={teamMembers}
              roomChat={roomChat}
              teamLead={teamLead}
              isLoading={isLoading}
              fetchTeamData={fetchTeamMembers}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <MessageOutlined /> Team Chat
              </span>
            }
            key="3"
          >
            <TeamChat membersParsed={teamMembers} roomChat={roomChat} />
          </TabPane>
        </Tabs>
      </div>
    </CustomLayout>
  );
}
