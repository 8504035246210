import React from 'react'
import CustomLayout from '../../../layouts/Customlayout'
import Chatbotsection from './Chatbotsection'
import ChatbotSetup from './FirstUser'
export default function ChatbotSectionCheck() {
    let firstLogin=JSON.parse(localStorage.getItem('UserObject'))?.firstLogin
  return (
    <div>
        <CustomLayout>
            {firstLogin?
            (
                <ChatbotSetup/>
            ):(
                <Chatbotsection/>
            )    
        }

        </CustomLayout>
        
    </div>
  )
}
