import React, { useEffect, useState, useTransition } from 'react';
import { List, Switch, Row,message,Flex } from 'antd';
import {
  MailOutlined,
  MinusOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  WhatsAppOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import CustomLayout from '../../../layouts/Customlayout';
import '../Css/AccountSettings.css';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';




const Chatbotsection = () => {
  
  const { t } = useTranslation();
  const customization = t("customization")
  const user = JSON.parse(localStorage.getItem('UserObject'));

  const [toggleConfig, setToggleConfig] = useState([
    {
      title: customization.disableForm,
      icon: MailOutlined,
      allow: false,
    },
  ]);
  const [toggleConfigDiable, setToggleConfigDiable] = useState([
    {
      title: customization.humanTakeover,
      icon: MailOutlined,
      allow: false,
    },
  ]);
  // const [toggleConfigDiable, setToggleConfigDiable] = useState();
  const [toggleEmailSummary, setToggleEmailSummary] = useState(false);
  const [selectedColor, setSelectedColor] = useState('');
  const [disableForm, setDisableForm] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  // const [sections, setSections] = useState([{ id: 1, value: '' }]);
  const [initialMsg, setInitialMsg] = useState("")
  const [sections, setSections] = useState([
    { id: 1, value: '' },

  ]);

  const [chatbotIcon, setChatbotIcon] = useState(null)
  const [previewChatbotIcon, setPreviewChatbotIcon] = useState(null)
  const [suggestionsList, setSuggestionsList] = useState([])
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const [humanTakeover, setHumanTakeover] = useState(false);
  const [PoweredByJaweb,setRemovePoweredByJaweb]= useState(true)

  const location = useLocation();





  const handleInputChange = (id, event) => {
    const newSections = sections.map(section => {
      if (section.id === id) {
        return { ...section, value: event.target.value };
      }
      return section;
    });
    setSections(newSections);
  };

  const resetMessage = () => {
    setInitialMsg('');
  };

  const resetInput = (id) => {
    const newSections = sections.map((section) => {
      if (section.id === id) {
        return { ...section, value: '' };
      }
      return section;
    });
    setSections(newSections);
  };

  const addSection = () => {
    if (sections.length < 4) {
      setSections([...sections, { id: sections.length + 1, value: '' }]);
    }
  };

  const removeSection = (id) => {
    setSections(sections.filter(section => section.id !== id));
  };
  const handleColorChange = (e) => {
    setSelectedColor(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file); // Save file to state
      console.log("File saved:", file);
  
      // Generate a preview URL for the uploaded file
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl); // Save preview URL to state
      console.log("Preview URL generated:", previewUrl);
    } else {
      console.error("No file selected");
    }
  };
  



  const fetchChatbotDetails = async () => {
    try {
      const company_name = JSON.parse(localStorage.getItem('UserObject')).username;
  
      const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };
  
      const response = await axios.get(`${config.apiUrl}chatbot-details/`, {
        params: { username: company_name },
        headers
      });
  
      const resData = response.data.data;
  
      // Map the user questions to sections
      const userQuesSugg = resData.user_questions;
      if (userQuesSugg) {
        const asections = userQuesSugg.map((item, index) => ({
          id: index + 1,
          value: item
        }));
        setSections(asections);
      }
  
      // Set various state variables from the response
      setSelectedColor(resData.chatbot_color);
      setInitialMsg(resData.chatbot_initial_msg);
      setDisableForm(resData.chatbot_form);
      setHumanTakeover(resData.disable_chatbot_globally);
      setRemovePoweredByJaweb(resData.remove_powered_by_jaweb);
      setToggleEmailSummary(resData.enable_chat_summary);
  
   
      if (resData.chatbot_logo) {
        setPreviewImage(resData.chatbot_logo);
      }


    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };
  useEffect(() => {
    fetchChatbotDetails();
  }, []);

  const handleSaveBtn = () => {

    setLoader(true)

    const userSuggestions = sections.map(i => i.value)
    console.log(initialMsg, imageFile, selectedColor, chatbotIcon, userSuggestions, toggleEmailSummary)

    const formData = new FormData();
    formData.append('chatbot_color', selectedColor);
    formData.append('chatbot_form', disableForm);
    formData.append('chatbot_initial_msg', initialMsg);
    formData.append('disable_chatbot_globally', humanTakeover);
    formData.append('enable_chat_summary', toggleEmailSummary)
    if (imageFile) {
      formData.append('chatbot_logo', imageFile);
    }
    if (chatbotIcon) {
      formData.append('chatbot_icon', chatbotIcon);
    }
    if (userSuggestions[0] != '') {
      formData.append('user_questions', userSuggestions)
    }

    const headers = {
      'Authorization': `Token ${localStorage.getItem('token')}`,
      'Accept': 'application/json',
    };

    axios.post(`${config.apiUrl}chatbot-details/`, formData, { headers })
      .then(res => {
        message.success('Settings saved successfully');

        setLoader(false)
        navigate('/user/integration')

        setSections([{ id: 1, value: '' }])
      })
      .catch(error => {
        setLoader(false)
        message.error('Error saving settings');
        console.error('Error posting data:', error);
      });
  };

  return (
      <div className="container wrapper_res">
        <Row gutter={[16, 16]} className="flex-container">
          <div className="content mainChat">
            <div className='header_chat_wrapper'>
              <h2 className='chatIn_head'> { customization.title }</h2>
            </div>
            <div className='flex bothWrapper'>
              <div className='halfLeft'>

                <div className='chatbotContent'>
                  <p className='note_para'>
                    {customization.note}
                  </p>
                  <div>
                    {/* <div class="main_inMsg_wrapper">
                      <div className='flex inMsg-wrapper'>
                        <label className="inMsg_head">Initial Messages</label>
                        <button className='resetBtn'>Reset</button>
                      </div>
                      <input type='text' className='inMsg_input' value={initialMsg} onChange={(e) => setInitialMsg(e.target.value)} />
                    </div> */}
                    <div className="main_inMsg_wrapper ">
                      <div className='flex inMsg-wrapper'>
                        <label className="inMsg_head">{ customization.initialMsg }</label>
                      </div>
                      <div className='icon_sugg'>
                        <input
                          type='text'
                          className='inMsg_input'
                          value={initialMsg}
                          onChange={(e) => setInitialMsg(e.target.value)}
                        />
                        {initialMsg && (
                          <CloseCircleOutlined className='icon_sugg_pos' onClick={resetMessage} />
                        )}
                      </div>

                    </div>
                    <div className="main_inMsg_wrapper mt-cus">
                      <div>
                        <div className='suggWrapper_chat'>
                          <label className="inMsg_head">{customization.suggestedMsg}</label>
                          {/* <button className='resetBtn' onClick={resetSections}>Reset</button> */}
                        </div>

                        {sections.map((section, index) => (
                          <div key={section.id} className='flex mt-3 mb-3 gap-2'>
                            <div className='icon_sugg sug-wid'>
                              <input
                                className='border forWid cus-border-sug forPad'
                                placeholder='Add Options'
                                value={section.value}
                                onChange={(event) => handleInputChange(section.id, event)}
                              />
                              {section.value && (
                                <CloseCircleOutlined
                                  className='icon_sugg_op'
                                  onClick={() => resetInput(section.id)}
                                />
                              )}
                            </div>
                            {index !== 0 && (
                              <button
                                className='border border-gray-300 text-white text-sm cus-border-sug op-btn block p-2.5 bg-[#7F28F8] dark:placeholder-gray-400 dark:text-white'
                                onClick={() => removeSection(section.id)}
                              >
                                <MinusOutlined />
                              </button>
                            )}
                            {index === sections.length - 1 && (
                              <button
                                className='border border-gray-300 text-white text-sm cus-border-sug op-btn block p-2.5 bg-[#7F28F8] dark:placeholder-gray-400 dark:text-white'
                                onClick={addSection}
                              >
                                <PlusOutlined />
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* <div class="main_inMsg_wrapper mt-cus">
                      <div className='flex inMsg-wrapper'>
                        <label className="inMsg_head">Theme</label>
                      </div>
                      <select className='inMsg_input'>
                        <option value="light">Light</option>
                        <option value="dark">Dark</option>
                      </select>
                    </div> */}
                    <div class="main_inMsg_wrapper mt-cus">
                      <div className='flex inMsg-wrapper'>
                        <label className="inMsg_head">{customization.chatbotProfile}</label>
                      </div>
                      <input type='file' onChange={handleImageChange} className='inMsg_input' />
                    </div>
                    {/* <div class="main_inMsg_wrapper mt-cus">
                      <div className='flex inMsg-wrapper '>
                        <label className="inMsg_head ">Remove Chatbot profile picture</label>
                      </div>
                      <input type='checkbox' className='inMsg_input inMsg_input_wid' />
                    </div> */}
                    {/* <div class="main_inMsg_wrapper mt-cus">
                      <div className='flex inMsg-wrapper'>
                        <label className="inMsg_head">Display name</label>
                      </div>
                      <input type='text' className='inMsg_input' />
                    </div> */}
                    <div class="main_inMsg_wrapper mt-cus">
                      <div className='flex inMsg-wrapper'>
                        <label className="inMsg_head">{customization.theme}</label>
                        {/* <button className='resetBtn'>Reset</button> */}

                      </div>

                      <input type='color' className='inMsg_input inMsg_input_wid_color' value={selectedColor} onChange={handleColorChange} />
                      <p className='msg_note'>{customization.themeNote}</p>
                    </div>
                    {/* <div class="main_inMsg_wrapper mt-cus">
                      <div className='flex inMsg-wrapper'>
                        <label className="inMsg_head">{customization.chatbotIcon}</label>
                      </div>
                      <input type='file' onChange={handleChatbotIcon} className='inMsg_input' />
                    </div> */}
                    <List className='mt-cus'
                      itemLayout="horizontal"
                      dataSource={[
                        {
                          title: customization.disableForm,
                          icon: MailOutlined,
                          allow: false,
                        },
                      ]}
                      renderItem={item => (
                        <List.Item className='pd-0'>
                          <Flex className="w-[100%] justify-between items-center font-weight-500">
                            <div className="mr-7">
                              <h4 className="mb-0 fw-500">{item.title}</h4>
                            </div>
                            <div>
                              <Switch
                                checked={disableForm}
                                onChange={checked => {
                                  setDisableForm(checked);
                                }}
                                className="switcher_clr"
                              />
                            </div>
                          </Flex>
                        </List.Item>
                      )}

                    />
                      <List className='mt-cus'
                      itemLayout="horizontal"
                      dataSource={[
                            {
                              title: customization.humanTakeover,
                              icon: MailOutlined,
                              allow: false,
                            },
                          ]}
                      renderItem={item => (
                        <List.Item className='pd-0'>
                          <Flex className="w-[100%] justify-between items-center font-weight-500">
                            <div className="mr-7">
                              <h4 className="mb-0 fw-500">{item.title}</h4>
                            </div>
                            <div>
                              <Switch
                                checked={humanTakeover}
                                onChange={checked => {
                                  setHumanTakeover(checked);
                                }}
                                className="switcher_clr"
                              />
                            </div>
                          </Flex>
                        </List.Item>
                      )}
                      
                    />



                      <List className='mt-cus'
                      itemLayout="horizontal"
                      dataSource={[
                          {
                            title: customization.emailSummary,
                            icon: MailOutlined,
                            allow: false,
                          },
                      ]}
                      renderItem={item => (
                        <List.Item className='pd-0'>
                          <Flex className="w-[100%] justify-between items-center font-weight-500">
                            <div className="mr-7">
                              <h4 className="mb-0 fw-500">{item.title}</h4>
                            </div>
                            <div>
                              <Switch
                                checked={toggleEmailSummary}
                                onChange={checked => {
                                  setToggleEmailSummary(checked);
                                }}
                                className="switcher_clr"
                              />
                            </div>
                          </Flex>
                        </List.Item>
                      )}
                      
                    />

                     <div className='mt-4'>
                        <button className='save_data btndata' onClick={handleSaveBtn}>
                       {
                      loader && <Spin style={{ coloe: '#fff' }} className='spin_bg'/> }
                        {customization.save}</button>
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="verticalLine"
                style={{
                  borderLeft: '2px solid rgba(128, 40, 248, 0.281)',
                  height: '1200px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              ></div>

              <div className='halfRight '>
                <div class="fixed-container">
                  <div className="chat-box w-9" style={{ border: `1px solid ${selectedColor}` }}>
                    <div className="header_chat" style={{ background: selectedColor }}>
                      <div>
                        <img src={previewImage ? previewImage : "https://www.shutterstock.com/image-vector/chat-bot-logo-design-concept-600nw-1938811039.jpg" } alt="Logo" />
                      </div>

                      {
                        JSON.parse(localStorage.getItem('UserObject')).phone_number_id ? <WhatsAppOutlined/> : '' 
                      }

                    </div>

                    <div class="messages-container">
                      {initialMsg && (
                        <div className="message business-message">
                          <p className='initialMsg_text'>{initialMsg}</p>
                        </div>
                      )}
                      {/* <div class="message user-message" style={{ background: selectedColor }}>
                        <p style={{ color: '#fff' }}>Hii</p>
                      </div> */}

                    </div>
                    {sections.some(section => section.value.trim() !== '') && (
                      <div className="premadechat-wrapper-new">
                        {sections.map((data) => (
                          data.value.trim() !== '' && (
                            <div className="conversation-item-text-new" key={data.id}>
                              <p>{data.value}</p>
                            </div>
                          )
                        ))}
                      </div>
                    )}
                    {/* 

                    <div class="premadechat-wrapper-new">

                      {sections.length > 0 && sections.map((data, index) => (
                        <div class="conversation-item-text-new" key={data.id} style={{ border: `1px solid ${selectedColor}` }}>
                          <p>{data.value}</p>
                        </div>
                      ))}

                    </div> */}

                    <div class="input-container">
                      <input type="text" placeholder={customization.sendMsgPlaceholder} style={{ border: `1px solid ${selectedColor}` }} />
                      <button type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="_svg_1cum9_1"><path d="M19.5656 12.5913C19.4092 12.9268 19.1426 13.1929 18.8233 13.3529L6.75036 19.4461C5.89387 19.8649 4.86032 19.5233 4.42563 18.6844C4.2343 18.2793 4.19549 17.822 4.32795 17.3955L5.64831 13.0972C5.7692 12.7037 6.13274 12.435 6.54448 12.435H11.1654C11.505 12.4337 11.7828 12.1592 11.7905 11.8101C11.7884 11.4663 11.514 11.1885 11.1654 11.1851H6.54788C6.13452 11.1851 5.76995 10.9144 5.65042 10.5187L4.34749 6.20514C4.07489 5.3055 4.58948 4.34594 5.50008 4.07643C5.92157 3.94393 6.37753 3.98415 6.76989 4.19362L18.8233 10.2868C19.6653 10.7168 20.0041 11.7517 19.5656 12.5913Z" fill={selectedColor}></path></svg>
                      </button>
                    </div>
                    {!PoweredByJaweb? 

                    <div className="flex items-center justify-center">
                    <a  href="https://jaweb.me/" target="_blank" rel="noopener noreferrer">
                      <span className="text-gray-500">
                      Powered By Jaweb

                        </span>
                    </a>
                  </div>:
                  <></>
                  }
                  </div>
                </div>
                <div className='mt-4'>
                  <button className='fixed_icon' style={{ background: selectedColor }}>
                    <img src={'https://static.ada.support/images/e1f9c0f5-51ce-45e4-8240-41d357ef3ed8.svg'} alt='chatIcon' className='chatBot_img'></img>
                  </button>
                </div>
              </div>
            </div>


          </div>

        </Row>
      </div>
  );
};

export default Chatbotsection;
