import React, { useState, useEffect } from "react";
import { Button, Typography, message, Table, Card } from "antd";
import { CopyOutlined, CheckOutlined, ShareAltOutlined, LogoutOutlined } from "@ant-design/icons";
import config from "../../config";

const { Title, Text } = Typography;

const AffiliateDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [affiliateData, setAffiliateData] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const fetchDashboardData = async () => {
    try {
      const response = await fetch(`${config.baseUrl}/affiliate/dashboard`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("affiliateToken")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch dashboard data.");
      }

      const data = await response.json();
      setAffiliateData(data);
    
      console.log(data)
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Profit from User",
      dataIndex: "profit",
      key: "profit",
      render: (profit) => <Text style={{ color: "#7F28F8", fontWeight: "bold" }}>+${profit}</Text>,
    },
  ];

  const logout = () => {
    localStorage.removeItem("affiliateToken");
    window.location.href = "/program/affiliate/login";
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(affiliateData?.referral_link || "");
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000); // Reset copy button text after 2 seconds
  };

  const handleShareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Join Jaweb Affiliate Program",
          text: `Join Jaweb today and unlock the power of AI-driven conversations! 🚀 Share this link with your network and help them experience seamless automation and smart interactions.`,
          url: affiliateData?.referral_link || "",
        })
        .then(() => {
          message.success("Thanks for sharing!");
        })
        .catch((error) => {
          message.error("Could not share the link. Try again!");
        });
    } else {
      message.error("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div className="min-h-screen bg-white text-gray-900 px-6 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        {affiliateData?.name?<Title level={2} className="text-[#7F28F8]">Hello , {affiliateData?.name} 👋</Title>
:<></>}
        <Button
          onClick={logout}
          type="primary"
          danger
          icon={<LogoutOutlined />}
          className="bg-[#7F28F8] border-none hover:bg-[#6A1ECC]"
        >
          Logout
        </Button>
      </div>

      {/* Stats Section */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
        <Card className="text-center shadow-lg border-none" style={{ borderRadius: "10px" }}>
          <Title level={4} className="text-gray-600">Total Clicks</Title>
          <Text className="text-2xl font-bold text-[#7F28F8]">{affiliateData?.clicks || 0}</Text>
        </Card>
        <Card className="text-center shadow-lg border-none" style={{ borderRadius: "10px" }}>
          <Title level={4} className="text-gray-600">Total Signups</Title>
          <Text className="text-2xl font-bold text-[#7F28F8]">{affiliateData?.signups || 0}</Text>
        </Card>
        <Card className="text-center shadow-lg border-none" style={{ borderRadius: "10px" }}>
          <Title level={4} className="text-gray-600">Total Earned</Title>
          <Text className="text-2xl font-bold text-[#7F28F8]">${affiliateData?.earnings || "0.00"}</Text>
        </Card>
      </div>

      {/* Referral Link Section */}
      <div className="mb-8 p-6 rounded-lg shadow-lg bg-[#F9F9FF] flex flex-col sm:flex-row items-center sm:justify-between">
        <div>
          <Text className="text-gray-700">Your Referral Link</Text>
          <Title level={5} className="text-gray-900 m-0">
            {affiliateData?.referral_link || "Loading..."}
          </Title>
        </div>
        <div className="flex mt-4 sm:mt-0">
          <Button
            icon={copySuccess ? <CheckOutlined /> : <CopyOutlined />}
            onClick={handleCopyLink}
            className={`mr-2 ${copySuccess ? "bg-green-500" : "bg-[#7F28F8]"} text-white hover:bg-[#6A1ECC]`}
          >
            {copySuccess ? "Copied!" : "Copy Link"}
          </Button>
          <Button
            icon={<ShareAltOutlined />}
            onClick={handleShareLink}
            className="bg-white text-[#7F28F8] border-[#7F28F8] hover:bg-[#F1EBFF]"
          >
            Share
          </Button>
        </div>
      </div>

      {/* Referrals Table */}
      <Card title="My Referrals" className="shadow-lg border-none" style={{ borderRadius: "10px" }}>
        <Table
          dataSource={affiliateData?.performance || []}
          columns={columns}
          rowKey="date"
          pagination={{ pageSize: 5 }}
        />
      </Card>
    </div>
  );
};

export default AffiliateDashboard;
