import '../Css/Pricing.css';
import React, { useState } from 'react';
import { Typography, Badge, Calendar, Button, Alert, Space, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import config from '../../../config';
import { useStripe } from '@stripe/react-stripe-js';

const FreeTrialUsers = () => {
  // Retrieve referral information
  const userObject = JSON.parse(localStorage.getItem('UserObject') || '{}');
  const referredBy = userObject.referredBy || '';
  const stripe = useStripe();

  const isRivoliaReferral = referredBy === 'contact@rivolia.pro';

  const shopifyMonthlyPrice = isRivoliaReferral ? 26.99 : 29.99;
  const shopifyYearlyPrice = isRivoliaReferral ? 269.99 : 299.99;



  let standardMonthlyPrice=19.99;
  let standardYearlyPrice=199.99

  if (isRivoliaReferral) {
    standardMonthlyPrice = (standardMonthlyPrice * 0.9).toFixed(2); // 10% off
    standardYearlyPrice = (standardYearlyPrice * 0.9).toFixed(2); // 10% off
  }



  const trialEndDateString = localStorage.getItem('trial_end_date');
  const freeTrialEndDate = trialEndDateString
    ? dayjs(trialEndDateString)
    : dayjs().add(isRivoliaReferral ? 1 : 31, 'days'); // 1-day trial for Rivolia users, 31 days for others

  const freeTrialStartDate = freeTrialEndDate.subtract(isRivoliaReferral ? 1 : 31, 'days');


  const today = dayjs();
  const hasTrialEnded = today.isAfter(freeTrialEndDate);

  // Individual button loading states
  const [loadingPlan, setLoadingPlan] = useState({ monthly: false, yearly: false });

  const handlePlanSelection = async (plan) => {
    setLoadingPlan((prev) => ({ ...prev, [plan]: true }));
    try {
      const response = await axios.post(
        `${config.apiUrl}user/shopifySubscription`,
        { plan },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.data.confirmation_url) {
        window.location.href = response.data.confirmation_url;
      } else {
        message.error('Failed to get confirmation URL. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response?.data?.error) {
        message.error(`Error: ${error.response.data.error.join(', ')}`);
      } else {
        message.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoadingPlan((prev) => ({ ...prev, [plan]: false }));
    }
  };

  const handlePlanSelectionWeb = async (plan) => {
    setLoadingPlan((prev) => ({ ...prev, [plan]: true }));

    try {
      const token = localStorage.getItem("token"); // or get it from your context/state
  
      const response = await fetch(`${config.apiUrl}payment/create-checkout/?plan=${plan}`, {
        method: "GET",
        headers: {
          "Authorization": `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      const data = await response.json();
      console.log(data);
  
      if (!response.ok) {
        throw new Error(data.message || "Failed to create payment intent");
      }
  
      const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.log(error.message);
      // setError(error.message);
    }
  };
  
  return (
    <div className="flex flex-col items-center  min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Typography.Title level={3} className="text-center">
            {hasTrialEnded ? 'Your Free Trial Has Ended' : "You're on a Free Trial"}
          </Typography.Title>

          {!hasTrialEnded && (
            <Alert
              message="Your free trial is active."
              description={`Your free trial started on ${freeTrialStartDate.format(
                'MMMM D, YYYY'
              )} and will end on ${freeTrialEndDate.format(
                'MMMM D, YYYY'
              )}. Enjoy our services until then!`}
              type="info"
              showIcon
            />
          )}

          {hasTrialEnded && (
            <div>
              <Alert
                message="Your free trial has ended."
                description="Choose a plan below to continue enjoying our services."
                type="warning"
                showIcon
              />

              {isRivoliaReferral && (
                <div className="text-center font-bold text-green-600 mt-4">
                  🎉 Special Offer: <span className="text-lg">10% OFF</span> for Rivolia Users! 🎉
                </div>
              )}
              {userObject.mode==="shopify"?(
       <div className="h-full w-full flex gap-10 mt-6">
       <div className="pricing-card-dark">
         <h3>Shopify Access - Monthly</h3>
         <div className="price">
           <span>${shopifyMonthlyPrice}/month</span>
         </div>
         <div className="features">
           <div className="feature-item">
             <CheckOutlined /> <span>Access to dashboard with analytics</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>Zapier integration</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>WhatsApp integration</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>Real-time messaging</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>AI-powered chatbot</span>
           </div>

           <div className="feature-item">
             <CheckOutlined /> <span>Calendly integration</span>
           </div>

           <div className="feature-item">
             <CheckOutlined /> <span>Tracking of orders</span>
           </div>

           <div className="feature-item">
             <CheckOutlined /> <span>Cart Overview</span>
           </div>

           <div className="feature-item">
             <CheckOutlined /> <span>Answering questions about products</span>
           </div>
           
            
            
            
         </div>
         <button
           onClick={() => handlePlanSelection('monthly')}
           className="action-button-dark"
           disabled={loadingPlan.monthly}
         >
           {loadingPlan.monthly ? 'Processing...' : 'Choose Monthly'}
         </button>
       </div>

       {/* Yearly Plan Card */}
       <div className="pricing-card-dark">
         <h3>Shopify Access - Yearly (5% off)</h3>
         <div className="price">
           <span>${shopifyYearlyPrice}/year</span>
         </div>
         <div className="features">
           <div className="feature-item">
             <CheckOutlined /> <span>Access to dashboard with analytics</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>Zapier integration</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>WhatsApp integration</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>Real-time messaging</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>AI-powered chatbot</span>
           </div>

           <div className="feature-item">
             <CheckOutlined /> <span>Calendly integration</span>
           </div>

           <div className="feature-item">
             <CheckOutlined /> <span>Tracking of orders</span>
           </div>

           <div className="feature-item">
             <CheckOutlined /> <span>Cart Overview</span>
           </div>

           <div className="feature-item">
             <CheckOutlined /> <span>Answering questions about products</span>
           </div>
           
            
            
         </div>
         <button
           onClick={() => handlePlanSelection('yearly')}
           className="action-button-dark"
           disabled={loadingPlan.yearly}
         >
           {loadingPlan.yearly ? 'Processing...' : 'Choose Yearly'}
         </button>
       </div>
     </div>
              ):
              
              
              
        (
      <div className="h-full w-full flex gap-10 mt-6">
       <div className="pricing-card-dark">
         <h3>Standard - Monthly</h3>
         <div className="price">
           <span>${standardMonthlyPrice}/month</span>
         </div>
         <div className="features">
           <div className="feature-item">
             <CheckOutlined /> <span>Access to dashboard with analytics</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>Zapier integration</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>WhatsApp integration</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>Real-time messaging</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>AI-powered chatbot</span>
           </div>

           <div className="feature-item">
             <CheckOutlined /> <span>Calendly integration</span>
           </div>


           
            
            
            
         </div>
         <button
           onClick={() => handlePlanSelectionWeb('monthly')}
           className="action-button-dark"
           disabled={loadingPlan.monthly}
         >
           {loadingPlan.monthly ? 'Processing...' : 'Choose Monthly'}
         </button>
       </div>

       {/* Yearly Plan Card */}
       <div className="pricing-card-dark">
         <h3>Shopify Access - Yearly (5% off)</h3>
         <div className="price">
           <span>${standardYearlyPrice}/year</span>
         </div>
         <div className="features">
           <div className="feature-item">
             <CheckOutlined /> <span>Access to dashboard with analytics</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>Zapier integration</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>WhatsApp integration</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>Real-time messaging</span>
           </div>
           <div className="feature-item">
             <CheckOutlined /> <span>AI-powered chatbot</span>
           </div>
           
           <div className="feature-item">
             <CheckOutlined /> <span>Calendly integration</span>
           </div>

           
            
            
         </div>
         <button
           onClick={() => handlePlanSelectionWeb('yearly')}
           className="action-button-dark"
           disabled={loadingPlan.yearly}
         >
           {loadingPlan.yearly ? 'Processing...' : 'Choose Yearly'}
         </button>
       </div>
     </div>
              )}
       
            </div>
          )}
        </Space>
      </div>
    </div>
  );
};

export default FreeTrialUsers;
