import React, { useState, useEffect } from 'react';
import { Row, Col, Menu, message as antdMessage, message,Button } from 'antd';
import CustomLayout from '../../layouts/Customlayout';
import SettingOption from './components/MenuItems';
import UsersRender from './components/UsersRender';
import FreeTrialUsers from './components/FreeTrialUsers';

const Credit = () => {
    
  const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
  const shopify = UserInfo?.mode === 'shopify';
  let onTrial = localStorage.getItem('plan')==="free Trial" || localStorage.getItem('plan')==="free Trial ended"


  
  return (
    <CustomLayout>
      <div className='container'>
        <Row gutter={[16, 16]} className="flex-container">
          <Col xs={24} sm={24} md={8} className="menu">
            <SettingOption shopify={shopify}/>
          </Col>
          <Col xs={24} sm={24} md={16} className="content">
            {!onTrial?
              <UsersRender/>
            :
              <FreeTrialUsers/>
            }
          </Col>
        </Row>
      </div>
    </CustomLayout>
  );
};

export default Credit;
